<template>
  <modal
    class="modal-overlay default"
    :name="name"
    height="auto"
    :scrollable="true"
    @before-open="beforeOpen"
    @before-close="beforeClose"
  >
    <ModalLayout :name="name" type="default">
      <div class="modal-list">
        <div v-show="step === 0" class="modal-item">
          <div class="modal-pic">
            <img
              class="intro-1"
              src="~@/assets/img/pics/intro/1.png"
              width="553"
              height="411"
              alt=""
            />
          </div>
          <div class="modal-desc">
            Из&nbsp;небытия на&nbsp;наши миры движется армия Краноса, все десять
            миров в опасности! Для того, чтобы не&nbsp;дать Краносу захватить
            нашу вселенную десяти миров, нужно собрать камни величия для
            создания мегамолота, оружия способного победить Краноса...
          </div>
          <div class="modal-foot">
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее <span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 1" class="modal-item">
          <div class="modal-pic">
            <img
              class="intro-2"
              src="~@/assets/img/pics/intro/2.png"
              width="394"
              height="279"
              alt=""
            />
          </div>
          <div class="modal-desc">
            Для создания мегамолота нужно вернуть Камни величия&nbsp;&mdash; это
            9&nbsp;драгоценных самородков с&nbsp;девяти разных планет.
            Их&nbsp;собрали для того, чтобы инкрустировать ими двуручный
            мегамолот, но&nbsp;ужаснувшись силы оружия Старейшины миров
            в&nbsp;страхе разобрали свои артефакты и&nbsp;спрятали на&nbsp;своих
            планетах, поклявшись больше никогда не&nbsp;позволять собирать
            их&nbsp;вместе.
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее <span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 2" class="modal-item">
          <div class="modal-pic">
            <img
              class="intro-3"
              src="~@/assets/img/pics/intro/3.png"
              width="681"
              height="485"
              alt=""
            />
          </div>
          <div class="modal-desc">
            Сенат Обливии сохранил память о&nbsp;подсказках, способных помочь
            найти Камни. Карта с&nbsp;ними&nbsp;&mdash; в&nbsp;навигаторе
            каждого Дефендера.
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее <span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 3" class="modal-item">
          <div class="modal-pic">
            <img
              class="intro-4"
              src="~@/assets/img/pics/intro/4.png"
              width="697"
              height="387"
              alt=""
            />
          </div>
          <div class="modal-desc">
            Ищите капсулы с&nbsp;заданиями на&nbsp;разных планетах. Исследуйте
            планету за планетой и&nbsp;получайте GPB-coins за&nbsp;исследование!
            Зарабатывайте как можно больше GPB-coins!
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее <span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 4" class="modal-item">
          <div class="modal-pic">
            <img
              class="intro-5"
              src="~@/assets/img/pics/intro/5.png"
              width="535"
              height="293"
              alt=""
            />
          </div>
          <div class="modal-desc">
            Сумма GPB-coins каждого игрока составляет рейтинг команды. В
            рейтинге команды учитываются все заработанные GPB-coins, личные
            траты игроков не&nbsp;понижают рейтинг. Потратить личные GPB-coins
            вы можете в&nbsp;Зале славы Обливии.
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее <span></span>
            </button>
          </div>
        </div>
      </div>
    </ModalLayout>
  </modal>
</template>

<script>
import ModalLayout from "@/layout/ModalLayout.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    ModalLayout,
  },
  computed: {
    ...mapGetters(["getNewNotify"]),
  },
  data() {
    return {
      name: "legend",
      step: 0,
      closeTutorial: false,
    };
  },
  methods: {
    back() {
      this.step--;
    },
    next() {
      if (this.step < 4) {
        return this.step++;
      }

      this.$modal.hide(this.name);
    },
    beforeOpen(event) {
      this.step = 0;
      if (event.props && event.props.closeTutorial) {
        this.closeTutorial = true;
      }
      setTimeout(() => {
        document.getElementsByClassName("modal-close")[0].focus();
      }, 1000);
    },
    beforeClose() {
      if (!this.closeTutorial) {
        this.getNewNotify.forEach((notify) => {
          if (notify.notificationType === 3) {
            setTimeout(() => {
              this.$modal.show("tutorial", { id: notify.id });
            }, 400);
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pic {
  &-1 {
    margin-top: -60px;
  }
  &-2 {
    margin-bottom: 20px;
  }
  &-3 {
    margin-top: -110px;
  }
  &-4 {
    margin-top: -50px;
    margin-bottom: -10px;
  }
  &-5 {
    margin-top: -10px;
    margin-bottom: 20px;
  }
}

.modal {
  &-item {
    display: grid;
    grid-template-rows: 1fr auto 100px;
    height: calc(70vh - #{$headerHeight});
    min-height: 500px;
    max-height: 650px;
    margin-top: -60px;
  }
  &-pic {
    position: relative;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: auto;
      height: 100%;
      transform: translate3d(-50%, -50%, 0);
    }
    .intro-1 {
      height: 110%;
    }
    .intro-2 {
      height: 140%;
    }
    .intro-3 {
      margin-top: -20px;
    }
    .intro-4 {
      margin-left: 2%;
    }
  }
  &-button {
    margin-left: 7px;
    margin-right: 7px;
  }
}
</style>

<template>
  <div>
    <div class="page">
      <div class="page__inner">
        <!-- <div class="page__bg"></div> -->
        <div v-if="isPageNotHome" class="page__fog-top"></div>
        <div v-if="isPageNotHome" class="page__fog-bottom"></div>
        <div
          v-if="isAuthenticated && isPageNotFound"
          class="page__border-top"
        ></div>
        <div
          v-if="isAuthenticated && isPageNotFound"
          class="page__border-bottom"
        ></div>
        <slot />
      </div>
    </div>
    <slot name="modals" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["isAuthenticated"]),
    isPageNotHome() {
      const { name } = this.$route;
      return name !== "home";
    },
    isPageNotFound() {
      const { name } = this.$route;
      return name !== "404";
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  padding-top: $headerHeight;
  padding-bottom: $footerHeight;
  display: grid;
  min-height: 100vh;
  &_fullscreen {
    .page__inner {
      padding: 0;
      align-items: center;
    }
  }
  &__inner {
    position: relative;
    z-index: 1;
    padding-top: 80px;
    padding-bottom: 40px;
    display: grid;
    // align-items: center;
    justify-content: center;
  }
  &__fog {
    &-top {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      content: "";
      background-image: url(~@/assets/img/pic2/light.png);
      background-size: 100% auto;
      background-position: 50% 0;
      background-repeat: no-repeat;
      height: 20vw;
      z-index: -1;
    }
    &-bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      content: "";
      background-image: url(~@/assets/img/pic2/light.png);
      background-size: 140% auto;
      background-position: 50% 100%;
      background-repeat: no-repeat;
      height: 20vw;
      z-index: -1;
    }
  }
  &__border {
    &-top {
      position: fixed;
      top: $headerHeight;
      width: 100vw;
      height: 50vh;
      background-image: url(~@/assets/img/pic2/border-top.png);
      background-repeat: no-repeat;
      z-index: 110;
      background-size: 100% auto;
      pointer-events: none;
    }
    &-bottom {
      position: fixed;
      bottom: $footerHeight;
      width: 100vw;
      height: calc(100vh - #{$footerHeight});
      background-image: url(~@/assets/img/pic2/border-bottom.png);
      background-position: 0 100%;
      background-repeat: no-repeat;
      z-index: 100;
      background-size: 100% auto;
      pointer-events: none;
    }
  }
}
</style>

<template>
  <modal
    class="modal-overlay default"
    :name="name"
    height="auto"
    :scrollable="true"
    @before-open="beforeOpen"
  >
    <ModalLayout :name="name" type="default" class="modal-skin_type_long">
      <div class="stats">
        <div class="stats__head">
          <div class="stats__title">Статистика</div>
          <div class="stats__switcher">
            <div class="switcher" :class="{ active: mode === 'graphic' }">
              <button @click="mode = 'rating'" :disabled="mode === 'rating'">
                Рейтинг
              </button>
              <button @click="mode = 'graphic'" :disabled="mode === 'graphic'">
                График
              </button>
            </div>
          </div>
        </div>
        <div v-if="mode === 'rating'" class="stats__filter">
          <div v-if="title !== 'КК'" class="stats-filter">
            <button
              @click="onChangeFilter(0)"
              :class="{ active: filterType === 0 }"
              class="stats-filter__button"
            >
              Неделя
            </button>
            <button
              @click="onChangeFilter(1)"
              :class="{ active: filterType === 1 }"
              class="stats-filter__button"
            >
              Все время
            </button>
          </div>
        </div>
        <div class="stats__body">
          <div class="stats__name">{{ title }}</div>
          <div v-show="mode === 'graphic'" class="stats__section">
            <div class="stats__row">
              <div class="stats__col">
                <div class="stats__graphic">
                  <Chart
                    v-if="!loading && weeks.length && mode === 'graphic'"
                    :axisX="axisX"
                    :axisY="axisY"
                  />
                </div>
              </div>
              <div v-if="isCoins" class="stats__col">
                <div v-if="!loading" class="stats-list">
                  <div class="stats-list__head">
                    <div class="stats-list__thead">Неделя</div>
                    <div class="stats-list__thead">Дата</div>
                    <div class="stats-list__thead">Количество</div>
                  </div>
                  <div class="stats-list__body">
                    <div
                      v-for="(item, index) in weeks"
                      :key="index"
                      class="stats-list__item"
                    >
                      <div class="stats-list__week">
                        {{ item.index }} неделя
                      </div>
                      <div class="stats-list__date">
                        <!-- {{ item.date }} -->
                        <!-- {{ item.date | formatTimeRange }} -->
                        {{ (item.index - 1) | formatTimeRange }}
                      </div>
                      <div class="stats-list__value">{{ item.value }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="stats__col">
                <div v-if="!loading" class="stats-list">
                  <div class="stats-list__head">
                    <div class="stats-list__thead">Неделя</div>
                    <div class="stats-list__thead">Дата</div>
                    <div class="stats-list__thead">Результат</div>
                  </div>
                  <div class="stats-list__body">
                    <div
                      v-for="(item, index) in weeks"
                      :key="index"
                      class="stats-list__item"
                    >
                      <div class="stats-list__week">
                        {{ item.index }} неделя
                      </div>
                      <div class="stats-list__date">
                        <!-- {{ item.date }} -->
                        <template v-if="item.index !== undefined">
                          {{ item.index | formatTimeRange }}
                        </template>
                      </div>
                      <div class="stats-list__value">
                        <template v-if="isScore">
                          <div
                            :class="{
                              green: item.score === 5,
                              yellow: item.score === 4,
                              pink: item.score === 3,
                              red: item.score === 2,
                            }"
                          >
                            <strong>
                              <template v-if="item.score === 5">
                                зеленый
                              </template>
                              <template v-else-if="item.score === 4">
                                желтый
                              </template>
                              <template v-else-if="item.score === 3">
                                розовый
                              </template>
                              <template v-else-if="item.score === 2">
                                красный
                              </template>
                              <template v-else> - </template></strong
                            >
                          </div>
                        </template>
                        <template v-else> {{ item.value }}%</template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="mode === 'rating'" class="stats__section">
            <div v-if="isCoins" class="stats-rating">
              <div class="stats-rating__head">
                <div class="stats-rating__thead"></div>
                <div class="stats-rating__thead">ФИО</div>
                <div class="stats-rating__thead">Количество</div>
                <!-- <div class="stats-rating__thead">Значение KPI</div> -->
                <div class="stats-rating__thead">Место в рейтинге</div>
                <div class="stats-rating__thead">Динамика места</div>
              </div>
              <div class="stats-rating__body">
                <div
                  v-for="(item, index) in normalizeUsersCoins"
                  :key="index"
                  class="stats-rating__item"
                >
                  <div class="stats-rating__cell">
                    <div class="stats-rating__pic">
                      <img
                        v-if="item.photo"
                        :src="baseUrl + item.photo"
                        width="68"
                        height="68"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="stats-rating__cell">{{ item.fio }}</div>
                  <div class="stats-rating__cell">{{ item.value }}</div>
                  <div class="stats-rating__cell">{{ item.position }}</div>
                  <div class="stats-rating__cell">
                    <div
                      :class="{
                        down: item.positionDelta < 0,
                        up: item.positionDelta > 0,
                      }"
                    >
                      {{
                        item.positionDelta === 0
                          ? "без изменений"
                          : Math.abs(item.positionDelta)
                      }}
                    </div>
                  </div>
                </div>
                <!-- <div class="stats-rating__item">
                  <div class="stats-rating__cell">
                    <div class="stats-rating__pic"></div>
                  </div>
                  <div class="stats-rating__cell">Титов Петр Харламович</div>
                  <div class="stats-rating__cell">66,7 %</div>
                  <div class="stats-rating__cell">1</div>
                  <div class="stats-rating__cell">
                    <div class="up">2</div>
                  </div>
                </div>
                <div class="stats-rating__item">
                  <div class="stats-rating__cell">
                    <div class="stats-rating__pic"></div>
                  </div>
                  <div class="stats-rating__cell">Титов Петр Харламович</div>
                  <div class="stats-rating__cell">66,7 %</div>
                  <div class="stats-rating__cell">1</div>
                  <div class="stats-rating__cell">
                    <div class="up">2</div>
                  </div>
                </div>
                <div class="stats-rating__item">
                  <div class="stats-rating__cell">
                    <div class="stats-rating__pic"></div>
                  </div>
                  <div class="stats-rating__cell">Титов Петр Харламович</div>
                  <div class="stats-rating__cell">66,7 %</div>
                  <div class="stats-rating__cell">1</div>
                  <div class="stats-rating__cell">
                    <div class="up">2</div>
                  </div>
                </div>
                <div class="stats-rating__item">
                  <div class="stats-rating__cell">
                    <div class="stats-rating__pic"></div>
                  </div>
                  <div class="stats-rating__cell">Титов Петр Харламович</div>
                  <div class="stats-rating__cell">66,7 %</div>
                  <div class="stats-rating__cell">1</div>
                  <div class="stats-rating__cell">
                    <div class="up">2</div>
                  </div>
                </div>
                <div class="stats-rating__item">
                  <div class="stats-rating__cell">
                    <div class="stats-rating__pic"></div>
                  </div>
                  <div class="stats-rating__cell">Титов Петр Харламович</div>
                  <div class="stats-rating__cell">66,7 %</div>
                  <div class="stats-rating__cell">1</div>
                  <div class="stats-rating__cell">
                    <div class="up">2</div>
                  </div>
                </div>
                <div class="stats-rating__item">
                  <div class="stats-rating__cell">
                    <div class="stats-rating__pic"></div>
                  </div>
                  <div class="stats-rating__cell">Титов Петр Харламович</div>
                  <div class="stats-rating__cell">66,7 %</div>
                  <div class="stats-rating__cell">1</div>
                  <div class="stats-rating__cell">
                    <div class="up">2</div>
                  </div>
                </div>
                <div class="stats-rating__item">
                  <div class="stats-rating__cell">
                    <div class="stats-rating__pic"></div>
                  </div>
                  <div class="stats-rating__cell">Титов Петр Харламович</div>
                  <div class="stats-rating__cell">66,7 %</div>
                  <div class="stats-rating__cell">1</div>
                  <div class="stats-rating__cell">
                    <div class="down">10</div>
                  </div>
                </div> -->
              </div>
            </div>
            <div v-else class="stats-rating">
              <div class="stats-rating__head">
                <div class="stats-rating__thead"></div>
                <div class="stats-rating__thead">ФИО</div>
                <div class="stats-rating__thead">Значение KPI</div>
                <div class="stats-rating__thead">Место в рейтинге</div>
                <div class="stats-rating__thead">Динамика места</div>
              </div>
              <div class="stats-rating__body">
                <div
                  v-for="(item, index) in normalizeKpiData"
                  :key="index"
                  class="stats-rating__item"
                >
                  <div class="stats-rating__cell">
                    <div class="stats-rating__pic">
                      <img
                        v-if="item.photo"
                        :src="baseUrl + item.photo"
                        width="68"
                        height="68"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="stats-rating__cell">{{ item.fio }}</div>
                  <div class="stats-rating__cell">
                    <template v-if="title === 'КК'">
                      <div
                        :class="{
                          green: item.value === 5,
                          yellow: item.value === 4,
                          pink: item.value === 3,
                          red: item.value === 2,
                        }"
                      >
                        <strong>
                          <template v-if="item.value === 5"> зеленый </template>
                          <template v-else-if="item.value === 4">
                            желтый
                          </template>
                          <template v-else-if="item.value === 3">
                            розовый
                          </template>
                          <template v-else-if="item.value === 2">
                            красный
                          </template>
                          <template v-else> - </template></strong
                        >
                      </div>
                    </template>
                    <!-- <template v-if="isScore">
                      <div
                        :class="{
                          green: item.score === 5,
                          yellow: item.score === 4,
                          pink: item.score === 3,
                          red: item.score === 2,
                        }"
                      >
                        <strong>
                          <template v-if="item.score === 5"> зеленый </template>
                          <template v-else-if="item.score === 4">
                            желтый
                          </template>
                          <template v-else-if="item.score === 3">
                            розовый
                          </template>
                          <template v-else-if="item.score === 2">
                            красный
                          </template>
                          <template v-else> - </template></strong
                        >
                      </div>
                    </template> -->
                    <template v-else> {{ item.value }}{{ unitKPI }} </template>
                  </div>
                  <div class="stats-rating__cell">{{ item.position }}</div>
                  <div class="stats-rating__cell">
                    <div :class="{ down: item.delta < 0, up: item.delta > 0 }">
                      {{
                        item.delta === 0
                          ? "без изменений"
                          : Math.abs(item.delta)
                      }}
                    </div>
                  </div>
                </div>
                <!-- <div class="stats-rating__item">
                  <div class="stats-rating__cell">
                    <div class="stats-rating__pic"></div>
                  </div>
                  <div class="stats-rating__cell">Титов Петр Харламович</div>
                  <div class="stats-rating__cell">66,7 %</div>
                  <div class="stats-rating__cell">1</div>
                  <div class="stats-rating__cell">
                    <div class="up">2</div>
                  </div>
                </div>
                <div class="stats-rating__item">
                  <div class="stats-rating__cell">
                    <div class="stats-rating__pic"></div>
                  </div>
                  <div class="stats-rating__cell">Титов Петр Харламович</div>
                  <div class="stats-rating__cell">66,7 %</div>
                  <div class="stats-rating__cell">1</div>
                  <div class="stats-rating__cell">
                    <div class="up">2</div>
                  </div>
                </div>
                <div class="stats-rating__item">
                  <div class="stats-rating__cell">
                    <div class="stats-rating__pic"></div>
                  </div>
                  <div class="stats-rating__cell">Титов Петр Харламович</div>
                  <div class="stats-rating__cell">66,7 %</div>
                  <div class="stats-rating__cell">1</div>
                  <div class="stats-rating__cell">
                    <div class="up">2</div>
                  </div>
                </div>
                <div class="stats-rating__item">
                  <div class="stats-rating__cell">
                    <div class="stats-rating__pic"></div>
                  </div>
                  <div class="stats-rating__cell">Титов Петр Харламович</div>
                  <div class="stats-rating__cell">66,7 %</div>
                  <div class="stats-rating__cell">1</div>
                  <div class="stats-rating__cell">
                    <div class="up">2</div>
                  </div>
                </div>
                <div class="stats-rating__item">
                  <div class="stats-rating__cell">
                    <div class="stats-rating__pic"></div>
                  </div>
                  <div class="stats-rating__cell">Титов Петр Харламович</div>
                  <div class="stats-rating__cell">66,7 %</div>
                  <div class="stats-rating__cell">1</div>
                  <div class="stats-rating__cell">
                    <div class="up">2</div>
                  </div>
                </div>
                <div class="stats-rating__item">
                  <div class="stats-rating__cell">
                    <div class="stats-rating__pic"></div>
                  </div>
                  <div class="stats-rating__cell">Титов Петр Харламович</div>
                  <div class="stats-rating__cell">66,7 %</div>
                  <div class="stats-rating__cell">1</div>
                  <div class="stats-rating__cell">
                    <div class="up">2</div>
                  </div>
                </div>
                <div class="stats-rating__item">
                  <div class="stats-rating__cell">
                    <div class="stats-rating__pic"></div>
                  </div>
                  <div class="stats-rating__cell">Титов Петр Харламович</div>
                  <div class="stats-rating__cell">66,7 %</div>
                  <div class="stats-rating__cell">1</div>
                  <div class="stats-rating__cell">
                    <div class="down">10</div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="stats__body">
          <div class="stats__main">
            <div class="stats__graphic">
              <Chart
                v-if="!loading && weeks.length"
                :axisX="axisX"
                :axisY="axisY"
              />
            </div>
            <div class="stats__foot">
              <div v-if="!loading" class="stats-list">
                <div class="stats-list__body">
                  <div
                    v-for="(item, index) in normalizeWeeks"
                    :key="index"
                    class="stats-list__item"
                  >
                    <div class="stats-list__week">{{ item.index }} неделя</div>
                    <div class="stats-list__line"></div>
                    <div class="stats-list__date">
                      {{ item.index | formatTimeRange }}
                    </div>
                    <div class="stats-list__line"></div>
                    <div class="stats-list__value">
                      {{ item.value }}
                    </div>
                  </div>
                </div>
                <div
                  v-if="weeks.length > numCollapsedWeeks"
                  class="stats-list__foot"
                >
                  <button
                    @click="isCollapsedWeeks = !isCollapsedWeeks"
                    class="stats-list__button"
                  >
                    {{ isCollapsedWeeks ? "Свернуть" : "Раскрыть больше" }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="stats__sidebar">
            <div
              ref="usersList"
              class="stats-users"
              :class="{ 'stats-users_dots': showDots }"
            >
              <div v-if="loading" class="stats-users__inner">
                <div class="skeleton" v-for="(item, index) in 5" :key="index">
                  <div class="skeleton__circle"></div>
                  <div
                    class="skeleton__line h17 color1"
                    style="top: 15px; left: 125px; width: 160px"
                  ></div>
                  <div
                    class="skeleton__line h17 color2"
                    style="top: 35px; left: 125px; width: 180px"
                  ></div>
                  <div
                    class="skeleton__line h21 color3"
                    style="top: 60px; left: 125px; width: 250px"
                  ></div>
                </div>
              </div>
              <div v-else class="stats-users__inner">
                <div
                  v-for="(item, index) in normalizeUsers"
                  :key="index"
                  class="stats-users__item"
                  :class="{ 'stats-users__item_self': item.self }"
                >
                  <div class="stats-users__pic">
                    <img
                      v-if="item.photo"
                      :src="baseUrl + item.photo"
                      width="68"
                      height="68"
                      alt=""
                    />
                  </div>
                  <div class="stats-users__content">
                    <div class="stats-users__group">
                      <div
                        class="stats-users__text"
                        :class="{ 'stats-users__text_type_captain': item.self }"
                      >
                        {{ item.position }} место
                        <div>в рейтинге</div>
                      </div>
                      <div class="stats-users__text">
                        {{ item.value }}{{ unit ? unit : "" }}
                        <div>{{ unit ? "" : COINS }}</div>
                      </div>
                    </div>
                    <div class="stats-users__name">
                      {{ item.fio }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </ModalLayout>
  </modal>
</template>

<script>
import ModalLayout from "@/layout/ModalLayout.vue";

import Chart from "@/components/Chart.vue";

import { GetPlayerCoinHistory, GetPlayerKPIHistory } from "@/api/Player";
import { GetPlayersProfileKPIRating } from "@/api/Rating";
import { getBaseURL, mathRoundToDecimal } from "@/utils";

export default {
  components: {
    ModalLayout,
    Chart,
  },
  computed: {
    baseUrl() {
      return getBaseURL();
    },
    axisX() {
      return this.weeks
        .map((e) => {
          return e.index;
        })
        .reverse();
    },
    axisY() {
      return this.weeks
        .map((e) => {
          return e.value;
        })
        .reverse();
    },
    normalizeUsersCoins() {
      return this.filterType === 1
        ? this.usersCoinsTotal
        : this.usersCoinsWeekly;
    },
    normalizeUsers() {
      return this.filterType === 1 ? this.usersTotal : this.users;
    },
    normalizeKpiData() {
      return this.filterType === 1 ? this.kpiDataTotal : this.kpiDataWeekly;
    },
    selfValue() {
      if (!this.normalizeUsers.length) return "";

      const users = this.normalizeUsers;
      const self = users.find((e) => e.self);

      return self ? self.value : "";
    },
    showDots() {
      if (!this.normalizeUsers.length) return false;
      if (this.normalizeUsers.length <= 5) return false;

      const users = this.normalizeUsers;
      const self = users.find((e) => e.self);

      if (self.position > 7) return true;

      return false;
    },
  },
  data() {
    return {
      name: "statsRating",
      loading: false,
      mode: "rating",
      users: [],
      usersTotal: [],
      usersCoinsWeekly: [],
      usersCoinsTotal: [],
      weeks: [],
      isCoins: false,
      // weeks: [
      //   {
      //     index: 5,
      //     date: "с 20.08 по 26.08",
      //     value: 12,
      //   },
      //   {
      //     index: 4,
      //     date: "с 13.08 по 19.08",
      //     value: 46,
      //   },
      //   {
      //     index: 3,
      //     date: "с 06.08 по 12.08",
      //     value: 11,
      //   },
      //   {
      //     index: 2,
      //     date: "с 20.08 по 26.08",
      //     value: 25,
      //   },
      //   {
      //     index: 1,
      //     date: "с 20.08 по 26.08",
      //     value: 5,
      //   },
      // ],

      title: "",
      value: null,
      unit: "",
      unitKPI: "",
      kpiDataWeekly: [],
      kpiDataTotal: [],
      isScore: false,

      filterActive: false,
      filterType: 0,
    };
  },
  methods: {
    async fetchKPIRating(kpiId, isOverall) {
      try {
        const resp = await GetPlayersProfileKPIRating({ kpiId, isOverall });

        if (resp?.data?.positions) {
          resp.data.positions.forEach((e) => {
            e.value = mathRoundToDecimal(e.value);
          });
        }
        return resp?.data?.positions || [];
      } catch (error) {
        return [];
      }
    },
    async beforeOpen(event) {
      const { filter, data } = event.params;
      const {
        userId,
        kpiId,
        title,
        users,
        usersTotal,
        usersCoinsWeekly,
        usersCoinsTotal,
        unit,
      } = data;
      this.loading = true;
      this.mode = "rating";

      this.filterActive = filter ? true : false;
      this.filterType = 0;

      this.title = title;

      if (title === "КК") {
        this.isScore = true;
      } else {
        this.isScore = false;
      }

      if (usersCoinsWeekly || usersCoinsTotal) {
        this.usersCoinsTotal = usersCoinsTotal;
        this.usersCoinsWeekly = usersCoinsWeekly;
      } else {
        this.users = users || [];
        this.usersTotal = usersTotal || [];
      }

      this.unit = unit;

      // this.users = await this.fetchUsers();
      if (kpiId) {
        this.isCoins = false;
        this.weeks = await this.fetchKPIHistory(userId, kpiId);
        if (this.weeks[0] && this.weeks[0].measure) {
          this.unitKPI = this.weeks[0].measure;
        } else {
          this.unitKPI = "%";
        }

        if (this.title === "КК") {
          this.kpiDataWeekly = this.kpiDataTotal = await this.fetchKPIRating(
            kpiId,
            true
          );
        } else {
          this.kpiDataWeekly = await this.fetchKPIRating(kpiId, false);
          this.kpiDataTotal = await this.fetchKPIRating(kpiId, true);
        }
      } else {
        this.isCoins = true;
        this.weeks = await this.fetchCoinHistory(userId);
        this.unitKPI = "";
      }
      this.loading = false;
      return;
      // if (kpiId) {
      //   this.weeks = await this.fetchKPIHistory(userId, kpiId);
      // } else {
      //   this.weeks = await this.fetchCoinHistory(userId);
      // }

      // this.$nextTick(() => {
      //   this.loading = false;

      //   setTimeout(() => {
      //     let index = 0;
      //     this.users.forEach((e, i) => {
      //       if (e.self) {
      //         index = i;
      //       }
      //     });
      //     this.setScrollPosition(index);
      //   }, 0);
      // });

      // console.log(data);

      // setTimeout(() => {
      //   this.setScrollPosition(899);
      // }, 3000);
    },
    async fetchCoinHistory(userId) {
      const resp = await GetPlayerCoinHistory({
        playerId: userId,
      });
      return resp.data
        .map((e) => {
          return {
            index: e.week + 1,
            value: e.amount,
          };
        })
        .sort((a, b) => b.index - a.index);
    },
    async fetchKPIHistory(userId, kpiId) {
      const resp = await GetPlayerKPIHistory({
        playerId: userId,
        kpi: kpiId,
      });
      return resp.data
        .map((e) => {
          return {
            index: e.week,
            value: mathRoundToDecimal(e.absoluteValue),
            measure: e.measure,
            score: e.score,
          };
        })
        .sort((a, b) => b.index - a.index);
    },
    async fetchUsers() {
      this.loading = true;
      return new Promise((resolve) => {
        setTimeout(() => {
          this.loading = false;
          resolve([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
        }, 500);
      });
    },
    setScrollPosition(index) {
      const list = this.$refs.usersList;
      const items = list.querySelectorAll(".stats-users__item");

      items.forEach((element, i) => {
        if (i === index) {
          element.scrollIntoView({ block: "center" });
        }
      });
    },
    onChangeFilter(type) {
      this.filterType = type;

      // this.$nextTick(() => {
      //   let index = 0;
      //   this.normalizeUsers.forEach((e, i) => {
      //     if (e.self) {
      //       index = i;
      //     }
      //   });
      //   this.setScrollPosition(index);
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.stats {
  text-align: left;
  margin-top: -30px;
  margin-left: -80px;
  margin-right: -80px;
  margin-bottom: 0;
  &__head {
    text-align: center;
  }
  &__title {
    font-size: 40px;
    color: #5cffed;
  }
  &__switcher {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  &__coin {
    margin-top: 5px;
    font-size: 25px;
    color: #5cffed;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-bottom: -3px;
      margin-left: 10px;
      margin-right: -26px;
    }
  }
  &__filter {
    position: absolute;
    top: 112px;
    right: 60px;
  }
  &__body {
    margin-top: 10px;
  }
  &__name {
    font-size: 40px;
  }
  &__section {
    height: 380px;
  }
  &__graphic {
    margin-top: -27px;
    margin-left: -35px;
    margin-right: -65px;
    margin-bottom: -40px;
    height: 470px;
  }
  &__foot {
    margin-top: 40px;
  }
  &__sidebar {
    margin-left: 50px;
    position: relative;
    min-height: 500px;
  }
  &-filter {
    text-align: right;
    &__button {
      @include reset-button;
      margin-left: 20px;
      &.active {
        color: #5cffed;
        text-decoration: underline;
        pointer-events: none;
      }
      font-size: 17px;

      color: #457973;
    }
  }
  &__section {
    width: 100%;
  }
  &__row {
    display: grid;
    grid-template-columns: 590px auto;
    gap: 35px;
  }
  &__col {
  }
  &-list {
    position: relative;
    // margin-top: 63px;
    // margin-top: -23px;
    &__head {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      text-align: center;
      margin-right: 15px;
    }
    &__thead {
      font-size: 16px;
      color: #ade5d9;
    }
    &__body {
      margin-top: 10px;
      overflow: auto;
      height: 370px;
      @include scrollbar;
    }
    &__item {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      text-align: center;
      background: rgba(0, 255, 254, 0.15);
      font-size: 18px;
      line-height: 25px;
      padding: 15px 0;
      margin-right: 15px;
      + .stats-list__item {
        margin-top: 8px;
      }
    }
    &__week {
    }
    &__date {
    }
    &__value {
    }
    &__foot {
    }
    &__button {
      @include reset-button;
    }
  }
  &-users {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    @include scrollbar;
    &_dots {
      .stats-users__item:nth-child(5) {
        &::after {
          position: absolute;
          top: 100%;
          content: "...";
          margin-top: -15px;
          left: 0;
          color: #5cffed;
          font-size: 22px;
          width: 68px;
          text-align: center;
        }
      }
    }
    &__inner {
      display: grid;
      gap: 0px;
    }
    &__item {
      display: grid;
      grid-template-columns: 100px auto;
      height: 100px;
      position: relative;
      padding-left: 25px;
      padding-top: 15px;
      &_self {
        background: rgba(0, 255, 254, 0.15);
      }
    }
    &__pic {
      width: 68px;
      height: 68px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
      border-radius: 68px;
      background-image: url(~@/assets/img/pic2/avatar-default-small.png);
      background-size: contain;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100px;
        overflow: hidden;
      }
    }
    &__content {
      // margin-top: -2px;
    }
    &__group {
      display: grid;
      grid-template-columns: 110px auto;
    }
    &__text {
      font-size: 15px;
      color: #5cffed;
      position: relative;
      &_type_captain {
        &::before {
          position: absolute;
          top: 0;
          right: 100%;
          width: 24px;
          height: 20px;
          content: "";
          margin-top: -1px;
          margin-right: 4px;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='20' viewBox='0 0 24 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_dd)'%3E%3Cpath d='M12 4.6517l1.4616 3.7486.149.382H18.41l-3.4513 2.0897-.4315.2613.1832.4699 1.3787 3.5359-3.7783-2.2876L12 12.6633l-.3108.1882-3.7783 2.2876 1.3787-3.5359.1832-.4699-.4315-.2613L5.59 8.7824h4.7994l.149-.382L12 4.6516z' stroke='%2352FFEC' stroke-width='1.2' shape-rendering='crispEdges'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_dd' x='.4404' y='0' width='23.119' height='19.5676' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset/%3E%3CfeGaussianBlur stdDeviation='1.5'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset/%3E%3CfeGaussianBlur stdDeviation='1'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0.638194 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0'/%3E%3CfeBlend mode='normal' in2='effect1_dropShadow' result='effect2_dropShadow'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect2_dropShadow' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
        }
      }
      & > div {
        color: #87aaa6;
      }
    }
    &__name {
      font-family: $fontRafale;
      font-size: 20px;
      margin-top: 5px;
    }
  }
  &-rating {
    &.coins {
      .stats-rating {
        &__head {
          grid-template-columns: 115px auto 200px 200px;
        }
        &__item {
          grid-template-columns: 115px auto 200px 200px;
        }
      }
    }
    &__head {
      display: grid;
      grid-template-columns: 115px auto 200px 200px 200px;
      align-items: center;
      text-align: center;
      padding-right: 15px;
    }
    &__thead {
      font-size: 16px;
      line-height: 144.62%;
      color: #ade5d9;
      &:nth-child(2) {
        text-align: left;
      }
    }
    &__body {
      margin-top: 10px;
      overflow: auto;
      @include scrollbar;
      height: 370px;
      padding-right: 15px;
      display: grid;
      gap: 8px;
      align-content: start;
    }
    &__item {
      display: grid;
      grid-template-columns: 115px auto 200px 200px 200px;
      align-items: center;
      text-align: center;
      background: rgba(0, 255, 254, 0.15);
      height: 55px;
      .up {
        position: relative;
        display: inline-block;
        font-weight: 700;
        color: #10f466;
        &::before {
          position: absolute;
          right: 100%;
          top: 50%;
          margin-top: -3px;
          margin-right: 8px;
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 5px 9px 5px;
          border-color: transparent transparent #10f466 transparent;
        }
      }
      .down {
        position: relative;
        display: inline-block;
        font-weight: 700;
        color: #ff6161;
        &::before {
          position: absolute;
          right: 100%;
          top: 50%;
          margin-top: -3px;
          margin-right: 8px;
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 9px 5px 0 5px;
          border-color: #ff6161 transparent transparent transparent;
        }
      }
    }
    &__cell {
      &:nth-child(2) {
        text-align: left;
      }
    }
    &__pic {
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
      border-radius: 68px;
      background-image: url(~@/assets/img/pic2/avatar-default-small.png);
      background-size: contain;
      margin-left: 24px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100px;
        overflow: hidden;
      }
    }
  }
}

.skeleton {
  position: relative;
  height: 100px;
  animation-timing-function: steps(20, end);
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 1s;
  animation-name: blink;
  &__circle {
    width: 68px;
    height: 68px;
    border-radius: 68px;
    background-color: #fff;
    margin-left: 25px;
    margin-top: 15px;
  }
  &__line {
    position: absolute;
    &.h17 {
      height: 14px;
    }
    &.h21 {
      height: 17px;
    }
    &.color1 {
      background-color: #5cffed;
    }
    &.color2 {
      background-color: #87aaa6;
    }
    &.color3 {
      background-color: #fff;
    }
  }
}

@keyframes blink {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
</style>

<template>
  <div>
    <img v-if="getProfile.photo" class="avatar" :src="userPhoto" alt="" />
    <div v-else class="avatar-default">
      <img
        src="@/assets/img/pic2/avatar-default-small.png"
        width="44"
        height="44"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getBaseURL } from "@/utils";

export default {
  computed: {
    ...mapGetters(["getProfile"]),
    userPhoto() {
      return getBaseURL() + this.getProfile.photo;
    },
  },
};
</script>

<style lang="scss">
.avatar {
  width: 44px;
  height: 44px;
  border-radius: 44px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  display: block;
  &-default {
    width: 44px;
    height: 44px;
    border-radius: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 44px;
    // border: 1px solid #fff;
    img {
      display: block;
    }
  }
}
</style>

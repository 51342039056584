import { GetPrize } from "@/api/OrderPlayer";
import {
  PRODUCT_ERROR,
  PRODUCT_FETCH,
  PRODUCT_SUCCESS,
} from "../../actions/shop/product";

const state = {
  product: {},
  status: "",
};

const getters = {
  getProduct: (state) => state.product,
  getProductStatus: (state) => state.status,
};

const actions = {
  [PRODUCT_FETCH]: ({ commit }, id) => {
    return new Promise((resolve) => {
      commit(PRODUCT_FETCH);
      GetPrize({ id })
        .then((resp) => {
          commit(PRODUCT_SUCCESS, resp.data);
          resolve();
        })
        .catch(() => {
          commit(PRODUCT_ERROR);
        });
    });
  },
};

const mutations = {
  [PRODUCT_FETCH]: (state) => {
    state.status = "loading";
  },
  [PRODUCT_SUCCESS]: (state, data) => {
    state.status = "success";
    state.product = data;
  },
  [PRODUCT_ERROR]: (state) => {
    state.status = "error";
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

<template>
  <v-chart class="chart" :option="option" />
</template>

<script>
import { LineChart } from "echarts/charts";
import { GridComponent, TooltipComponent } from "echarts/components";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import VChart from "vue-echarts";

use([GridComponent, TooltipComponent, LineChart, CanvasRenderer]);

export default {
  name: "Chart",
  props: {
    axisX: {
      type: Array,
      required: true,
    },
    axisY: {
      type: Array,
      required: true,
    },
  },
  components: {
    VChart,
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: "axis",
          formatter: "Неделя: {b0}<br/><b>Значение: {c0}</b>",
          backgroundColor: "rgba(2, 7, 17, .7)",
          borderColor: "#172E29",
          textStyle: {
            color: "#fff",
            fontFamily: "Alegreya Sans SC",
          },
        },
        xAxis: {
          type: "category",
          data: this.axisX,
          // data: [
          //   1,
          //   2,
          //   3,
          //   4,
          //   5,
          //   6,
          //   7,
          //   8,
          //   9,
          //   10,
          //   11,
          //   12,
          //   13,
          //   14,
          //   15,
          //   16,
          //   17,
          //   18,
          //   19,
          //   20,
          //   21,
          //   22,
          //   23,
          //   24,
          //   25,
          //   26,
          //   27,
          //   28,
          //   29,
          //   30
          // ],
          boundaryGap: false,
          axisLabel: {
            margin: 20,
            color: "#698C85",
            fontFamily: "Alegreya Sans SC",
          },
          axisLine: {
            lineStyle: {
              color: "#172E29",
              width: 3,
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            lineStyle: {
              color: "#172E29",
              width: 3,
            },
          },
          axisLabel: {
            margin: 30,
            color: "#ADE5D9",
            fontFamily: "Alegreya Sans SC",
            align: "left",
          },
        },
        series: [
          {
            data: this.axisY,
            // data: [
            //   0,
            //   32,
            //   41,
            //   93,
            //   50,
            //   30,
            //   32,
            //   30,
            //   32,
            //   41,
            //   93,
            //   50,
            //   30,
            //   32,
            //   90,
            //   32,
            //   41,
            //   93,
            //   50,
            //   30,
            //   32,
            //   50,
            //   32,
            //   41,
            //   93,
            //   50,
            //   30,
            //   32,
            //   11,
            //   90
            // ],
            type: "line",
            smooth: true,
            showSymbol: false,
            symbol: "circle",
            symbolSize: 10,
            itemStyle: {
              color: "#26EAFD",
            },
            lineStyle: {
              color: "#26EAFD",
              width: 4,
            },
            emphasis: {
              lineStyle: {
                color: "#26EAFD",
                width: 4,
              },
            },
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
.chart {
  height: 100%;
}
</style>

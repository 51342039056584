<template>
  <div class="modal-skin">
    <button v-if="!hideClose" class="modal-close" @click="$modal.hide(name)">
      <svg width="46" height="46" viewBox="0 0 46 46" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6 6h34v34H6V6zm1.2828 1.2828v31.4344h31.4344V7.2828H7.2828z"
          fill="#0FF"
        />
        <g opacity=".6" fill="#0FF">
          <path
            d="M46 46H34v-2.8237h9.1765V34H46v12zM12 46H0V34h2.8237v9.1763H12V46zM2.8237 12H0V0h12v2.8234H2.8237V12zM46 12h-2.8235V2.8234H34V0h12v12z"
          />
        </g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6 39.0828L39.0828 6 40 6.9172 6.9172 40 6 39.0828z"
          fill="#5EFFFF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M38.1368 39L7 7.8633 7.8632 7 39 38.1367 38.1368 39z"
          fill="#0FF"
        />
      </svg>
    </button>
    <div class="modal-skin__decor"></div>
    <div class="modal-skin__body" :class="type">
      <div class="modal-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    type: {
      type: String,
      default: "theme",
    },
    hideClose: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &-close {
    position: absolute;
    top: -20px;
    right: -60px;
    background: none;
    border: none;
    z-index: 100;
    &:hover {
      svg {
        path {
          fill: rgb(154, 255, 255);
        }
      }
    }
  }
  &-content {
    text-align: center;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  &-skin {
    width: 952px;
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50px;
      content: "";
      background-repeat: no-repeat;
      background-image: url(~@/assets/img/pic2/modal.svg);
    }
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50px;
      content: "";
      background-repeat: no-repeat;
      background-image: url(~@/assets/img/pic2/modal.svg);
      background-position: 0 100%;
    }
    &__body {
      background-image: url(~@/assets/img/pic2/modal-body.svg);
      background-repeat: repeat-y;
    }
    &_type_long {
      width: 1220px;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 900px;
        height: 50px;
        content: "";
        background-repeat: no-repeat;
        background-image: url(~@/assets/img/pic2/modal.svg);
      }
      &::after {
        position: absolute;
        top: 0;
        left: auto;
        right: 0;
        bottom: auto;
        width: calc(100% - 900px);
        height: 50px;
        content: "";
        background-repeat: no-repeat;
        background-image: url(~@/assets/img/pic2/modal.svg);
        background-position: 100% 0;
      }
      .modal-close {
        @media (max-width: 1400px) {
          top: -30px;
          right: 5px;
        }
      }
      .modal-skin {
        &__decor {
          &::before {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 900px;
            height: 50px;
            content: "";
            background-repeat: no-repeat;
            background-image: url(~@/assets/img/pic2/modal.svg);
            background-position: 0 100%;
          }
          &::after {
            position: absolute;
            right: 0;
            bottom: 0;
            width: calc(100% - 900px);
            height: 50px;
            content: "";
            background-repeat: no-repeat;
            background-image: url(~@/assets/img/pic2/modal.svg);
            background-position: 100% 100%;
          }
        }
        &__body {
          position: relative;
          background: none;
          &::before {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 900px;
            background-image: url(~@/assets/img/pic2/modal.svg);
            background-repeat: repeat-y;
            background-position: 0 -50px;
            content: "";
            z-index: -1;
          }
          &::after {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: calc(100% - 900px);
            background-image: url(~@/assets/img/pic2/modal.svg);
            background-repeat: repeat-y;
            background-position: 100% -50px;
            content: "";
            z-index: -1;
          }
        }
        &-content {
          // padding-left: 80px;
          // padding-right: 80px;
        }
      }
    }
  }
}
</style>

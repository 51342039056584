<template>
  <modal
    class="modal-overlay"
    :name="name"
    height="auto"
    @before-open="beforeOpen"
  >
    <ModalLayout :name="name" type="small">
      <div class="modal-title" v-html="message"></div>
      <div class="modal-text" v-html="text"></div>
      <div class="modal-foot">
        <button
          @click="handleCancel"
          class="button button-default button-modal"
        >
          {{ textButtonCancel }}
        </button>
        <button @click="handleClick" class="button button-default button-modal">
          {{ textButtonSubmit }}
        </button>
      </div>
    </ModalLayout>
  </modal>
</template>

<script>
import ModalLayout from "@/layout/ModalLayout.vue";

export default {
  components: {
    ModalLayout,
  },
  data() {
    return {
      name: "confirm",
      message: "",
      text: "",
      textButtonSubmit: "Да! Заказать!",
      textButtonCancel: "Отменить",
      cb: null,
    };
  },
  methods: {
    beforeOpen(event) {
      this.message = event.params?.message;
      this.text = event.params?.text;
      this.cb = event.params?.cb;
      if (event.params.textButtonSubmit) {
        this.textButtonSubmit = event.params.textButtonSubmit;
      } else {
        this.textButtonSubmit = "Да! Заказать!";
      }
      if (event.params.textButtonCancel) {
        this.textButtonCancel = event.params.textButtonCancel;
      } else {
        this.textButtonCancel = "Отменить";
      }
    },
    handleClick() {
      this.$modal.hide(this.name);
      if (typeof this.cb === "function") {
        this.cb();
      }
    },
    handleCancel() {
      this.$modal.hide(this.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &-content {
    text-align: center;
  }
  &-text {
    font-size: 20px;
    font-weight: 700;
  }
  &-foot {
    & > * {
      margin: 0 10px;
    }
  }
}
</style>

<style lang="scss">
.modal-text i {
  font-style: normal;
  color: #5effff;
}
</style>

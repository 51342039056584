import request from "@/utils/request";

export function GetTeamQuest(query) {
  return request({
    url: "/api/Player/TeamQuest/GetTeamQuest",
    method: "get",
    params: query,
  });
}

export function GetTeamQuestHistory(query) {
  return request({
    url: "/api/Player/TeamQuest/GetTeamQuestHistory",
    method: "get",
    params: query,
  });
}

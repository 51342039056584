<template>
  <div class="loader" :class="position + ' ' + theme">
    <div class="loader__spin"></div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: ["position", "theme"],
};
</script>

<style lang="scss" scoped>
.loader {
  display: flex;
  margin: 30px 0;
  &.center {
    justify-content: center;
  }
  &.small {
    .loader__spin {
      width: 36px;
      height: 36px;
      border-width: 3px;
    }
  }
  &.dark {
    .loader__spin {
      border-color: rgba(255, 255, 255, 0.1);
      border-left-color: #5cffed;
    }
  }
  &.game {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 100;
    transform: translate3d(-50%, -100px, 0);
    .loader__spin {
      width: 70px;
      height: 70px;
      border-width: 6px;
      border-color: rgba(255, 255, 255, 0.1);
      border-left-color: #5cffed;
    }
  }
  &__spin {
    width: 60px;
    height: 60px;
    border: 5px solid rgba(255, 255, 255, 0.1);
    border-left-color: #5cffed;
    border-radius: 70px;
    animation: 0.65s infinite rotate linear;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

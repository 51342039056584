export function numberWithZero(number) {
  return ("0" + number).slice(-2);
}
export function patternToIslandMedia(
  folder,
  number,
  name = "",
  separate = "",
  suffix = "",
  before = "/static/img/game/map",
  format = "png"
) {
  return `${before}/${folder}/${number}${separate}${name}${suffix}.${format}`;
}

<template>
  <modal
    class="modal-overlay theme"
    :name="name"
    height="auto"
    :scrollable="true"
    @before-open="beforeOpen"
  >
    <ModalLayout :name="name" type="theme theme_wide">
      <section v-show="step === 1">
        <div class="modal-pic">
          <img
            class="pic-start"
            :src="islands[islandID] && islands[islandID].startPic"
            width="510"
            height="360"
            alt=""
          />
        </div>
        <div
          class="modal-title"
          v-html="islands[islandID] && islands[islandID].startTitle"
        ></div>
        <div
          class="modal-desc"
          v-html="islands[islandID] && islands[islandID].startDesc"
        ></div>
      </section>
      <section v-show="step === 2">
        <video
          class="modal-video"
          v-if="islandID"
          :src="islands[islandID].videoSrc"
          ref="video"
          controls
        ></video>
        <div
          class="modal-desc"
          v-html="islands[islandID] && islands[islandID].text"
        ></div>
      </section>
      <section v-show="step === 3">
        <div class="modal-pic">
          <img
            class="pic-start"
            :src="islands[islandID] && islands[islandID].startPic"
            width="510"
            height="360"
            alt=""
          />
        </div>
        <div class="modal-title" style="margin-bottom: 0">
          Защитники Вселенной, вы&nbsp;собрали мегамолот и&nbsp;готовы
          к&nbsp;битве с&nbsp;Краносом!
        </div>
      </section>
      <section v-show="step === 4">
        <div class="modal-pic pic-final">
          <img
            class="pic-start"
            src="@/assets/img/pic2/final.png"
            width="476"
            height="337"
            alt=""
          />
        </div>
        <div class="modal-title" style="margin-bottom: 0">
          Кранос повержен!<br />Ваша игра завершена.
        </div>
      </section>
      <div class="modal-foot button-group">
        <button
          v-if="buttonText === 'ОК'"
          @click="nextStep(true)"
          class="button-default button_big modal-button"
        >
          Более не показывать
        </button>
        <button
          @click="nextStep"
          class="button-default button_big modal-button"
        >
          {{ buttonText }}
        </button>
      </div>
    </ModalLayout>
  </modal>
</template>

<script>
import ModalLayout from "@/layout/ModalLayout.vue";
import { addModal } from "@/utils/modalStorage";

export default {
  components: {
    ModalLayout,
  },
  computed: {
    buttonText() {
      if (this.step === 1 || (this.step === 2 && this.gameOver)) {
        return "Далее";
      } else if (this.step === 3 && this.gameOver) {
        return "Начать битву";
      } else if (this.step === 4 && this.gameOver) {
        return "Ура!";
      }
      return "ОК";
    },
  },
  data() {
    return {
      name: "islandAfter",
      step: 1,
      gameOver: false,
      islandID: null,
      islands: {
        asiris: {
          startPic: "/static/img/modal/molot/1.png",
          startTitle: "Камень Безумия получен!",
          startDesc:
            "Камень безумия позволяет удвоить силу атаки или защиты! Теперь молот вдвое мощнее, Краносу будет сложнее причинить вам вред!",
          videoSrc: "/static/video/space_/1.mp4",
          text: "Поскольку мирным расставание с&nbsp;Асирами никогда не&nbsp;бывало, нужно покинуть планету до&nbsp;того, как будет развернута полномасштабная кампания по&nbsp;поиску чужаков! Улетаем немедленно!",
        },
        timeless: {
          startPic: "/static/img/modal/molot/2.png",
          startTitle: "Камень Времени получен!",
          startDesc:
            "Теперь молот позволяет замедлять атакующих, и дает фору защитникам. Кранос просто не успеет причинить нам вред!",
          videoSrc: "/static/video/space_/2.mp4",
          text: "Главное&nbsp;&mdash; все делать быстро, иначе временной туннель закроется, и&nbsp;на&nbsp;планете придется торчать черт знает сколько декад... Туннель открыт, наш Фалкон готов к&nbsp;взлету!",
        },
        nibiru: {
          startPic: "/static/img/modal/molot/3.png",
          startTitle: "Камень Отражения получен!",
          startDesc:
            "Молот стал гораздо эффективнее при отражении ударов! Он позволяет выстраивать невидимую защиту и отбивать внезапные атаки.",
          videoSrc: "/static/video/space_/3.mp4",
          text: "Зеркальный поток&nbsp;&mdash; это единственный способ выбраться с&nbsp;планеты. Без него не&nbsp;увидеть безопасный путь. Сейчас путь виден лучше всего, следующее окно&nbsp;&mdash; через тысячу лет! Стартуем!",
        },
        kriptos: {
          startPic: "/static/img/modal/molot/4.png",
          startTitle: "Камень Логики получен!",
          startDesc:
            "Теперь молот обрел незримый разум и умеет вычислять самые слабые места противника, чтобы наносить максимальный урон.",
          videoSrc: "/static/video/space_/4.mp4",
          text: "На&nbsp;Криптосе начинается электромагнитная буря! Все в&nbsp;Фалкон!",
        },
        pandoria: {
          startPic: "/static/img/modal/molot/5.png",
          startTitle: "Камень Помыслов получен!",
          startDesc:
            "Камень помыслов усиливает любой импульс, который задан владельцем камня. Теперь ваше желание победить Краноса сделает оружие еще мощнее!",
          videoSrc: "/static/video/space_/5.mp4",
          text: "Световой день на&nbsp;Пандории кончается, а&nbsp;ночью неудачи не&nbsp;могут попасть в&nbsp;вас прицельно! Так что ретируемся немедленно!",
        },
        tatuum: {
          startPic: "/static/img/modal/molot/6.png",
          startTitle: "Камень Превосходства получен!",
          startDesc:
            "В туманном поясе камень превосходства необходим для ощущения собственного всесилия. Ведь победа невозможна без веры в себя!",
          videoSrc: "/static/video/space_/6.mp4",
          text: "Лазерное джет-топливо&nbsp;&mdash; единственный способ преодолеть сопротивление атмосферы Татуума, вы&nbsp;как раз залили баки до&nbsp;отказа! Надо пользоваться моментом, иначе топливо сдетонирует прямо в&nbsp;баке! Пуск двигателей!",
        },
        krediti: {
          startPic: "/static/img/modal/molot/7.png",
          startTitle: "Камень Бессмыслия получен!",
          startDesc:
            "Теперь молот позволяет предполагать самые нелогичные сценарии атаки и делает защиту эффективнее даже в самых опасных ситуациях.",
          videoSrc: "/static/video/space_/7.mp4",
          text: "На&nbsp;границе вихря астероидов дежурят банды мото-панков, которые гоняют на&nbsp;спор в&nbsp;астероидных потоках. Для того, чтобы они не&nbsp;прицепились к&nbsp;новенькому Фалкону, его надо замаскировать. Сейчас удачный момент, когда в&nbsp;лучах солнца корабль может пройти незамеченным!",
        },
        giperiya: {
          startPic: "/static/img/modal/molot/8.png",
          startTitle: "Камень Скорости получен!",
          startDesc:
            "За скорость отвечает именно этот камень, так что быстрые атаки и контратаки возможны только при его использовании.",
          videoSrc: "/static/video/space_/8.mp4",
          text: "Гиперия всегда рада гостям... Правда гости частенько оставались там навсегда. Суборбита Гиперии наполнена дрейфующими кораблями, которые не&nbsp;смогли вырваться вовремя, их&nbsp;экипажи состарились, а&nbsp;топливо кончилось... Большая удача попасть на&nbsp;гиперволну, идущую от&nbsp;планеты! Гиперволна вынесет нас в&nbsp;космос, поднимаем Фалкон!",
        },
        morroviya: {
          startPic: "/static/img/modal/molot/9.png",
          startTitle: "Камень Гибели получен!",
          startDesc:
            "Этот камень помогает стоять до конца и он же помогает завершить начатое в битве с противником. Мы готовы бросить вызов Краносу! Команда - в Фалкон!",
          videoSrc: "/static/video/space_/9.mp4",
          text: "После изъятия камня Гибели планета начала оживать со&nbsp;скоростью света. Из&nbsp;земли к&nbsp;солнцу стали тянуться гигантские лианы! Скорее! Нужно перепрыгнуть гиперпрыжком обратно к&nbsp;Обливии...К Фалкону!",
        },
      },
    };
  },
  methods: {
    beforeOpen(event) {
      this.step = 1;
      this.islandID = event.params.name;
      this.gameOver = event.params.gameOver || false;
      setTimeout(() => {
        document.getElementsByClassName("modal-close")[0].focus();
      }, 1000);
    },
    nextStep(lastShow) {
      if (lastShow === true) {
        addModal(this.name, this.islandID);
      }

      if (this.step === 1) {
        this.step = 2;
        this.$refs.video.play();
      } else if (this.step === 2 && this.gameOver) {
        this.step = 3;
      } else if (this.step === 3 && this.gameOver) {
        this.step = 4;
      } else {
        this.$modal.hide(this.name);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-video {
  width: 100%;
  margin: 0 auto 65px;
  aspect-ratio: 1920/1080;
}

.pic-start {
  margin-top: -50px;
  margin-bottom: -30px;
}

.pic-final {
  margin-bottom: 50px;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',[_c('ul',[(_vm.envDev)?_c('li',[_c('router-link',{attrs:{"to":{ name: 'kit' }}},[_vm._v("DevKit")])],1):_vm._e(),_c('li',[_c('router-link',{attrs:{"to":{ name: 'home' }}},[_vm._v("Карта")])],1),_c('li',{staticClass:"hover"},[_c('router-link',{class:{
          'router-link-active': [
            'teamGoal',
            'teamTest',
            'tournamentTable',
            'bonusTask' ].includes(_vm.$route.name),
        },attrs:{"to":{
          name: 'teamGoal',
        }}},[_vm._v("Командные задачи "),_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{ name: 'teamGoal' }}},[_vm._v("Командные цели")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'teamTest' }}},[_vm._v("Командные тестирования")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'tournamentTable' }}},[_vm._v("Турнирные таблицы")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'bonusTask' }}},[_vm._v("Бонусные задачи")])],1)])])],1),_c('li',[_c('router-link',{class:{
          'router-link-active':
            _vm.$route.name === 'usersCoins' ||
            _vm.$route.name === 'usersGratitudes' ||
            _vm.$route.name === 'usersIdeas' ||
            _vm.$route.name === 'usersBattles' ||
            _vm.$route.name === 'user',
        },attrs:{"to":{
          name: 'usersCoins',
        }}},[_vm._v("Рейтинг")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'battles' }}},[_vm._v("Поединок")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'store' }}},[_vm._v("Виртуальный магазин")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
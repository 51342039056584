import cart from "./cart";
import catalog from "./catalog";
import orders from "./orders";
import product from "./product";

const modules = {
  catalog,
  product,
  orders,
  cart,
};

export default {
  modules,
};

import { GetPlayerOrders } from "@/api/OrderPlayer";
import {
  ORDERS_ERROR,
  ORDERS_FETCH,
  ORDERS_SUCCESS,
} from "../../actions/shop/orders";

const state = {
  orders: [],
  status: "",
};

const getters = {
  getOrders: (state) => state.orders,
  getOrdersStatus: (state) => state.status,
};

const actions = {
  [ORDERS_FETCH]: ({ commit }) => {
    return new Promise((resolve) => {
      commit(ORDERS_FETCH);
      GetPlayerOrders()
        .then((resp) => {
          commit(ORDERS_SUCCESS, resp.data);
          resolve();
        })
        .catch(() => {
          commit(ORDERS_ERROR);
        });
    });
  },
};

const mutations = {
  [ORDERS_FETCH]: (state) => {
    state.status = "loading";
  },
  [ORDERS_SUCCESS]: (state, data) => {
    state.status = "success";
    state.orders = data;
  },
  [ORDERS_ERROR]: (state) => {
    state.status = "error";
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

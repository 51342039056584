import store from "@/store";
import { getBaseURL } from "@/utils";
import { getToken } from "@/utils/auth";
import axios from "axios";

// create an axios instance
const service = axios.create({
  baseURL: getBaseURL(), // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  // timeout: 5000 // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    config.headers["Content-Type"] = "application/json";
    if (store.getters.isAuthenticated) {
      config.headers["Authorization"] = "Bearer " + getToken();
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.config && error.response && error.response.status === 401) {
      const userName = location.search.split("userName=")[1];

      if (store.getters.isAuthenticated) {
        store.dispatch("AUTH_LOGOUT");
      }

      if (store.getters.authStatus === "loading") {
        return new Promise((resolve, reject) => {
          const unwatch = store.watch(
            (state, getters) => getters.authStatus,
            (newValue) => {
              if (newValue === "success") {
                resolve();
              } else {
                reject();
              }
              unwatch();
            }
          );
        }).then(() => {
          error.config.headers["Authorization"] = "Bearer " + getToken();
          return axios.request(error.config);
        });
      } else {
        return store.dispatch("AUTH_FETCH", userName).then(() => {
          error.config.headers["Authorization"] = "Bearer " + getToken();
          return axios.request(error.config);
        });
      }
    }
    return Promise.reject(error.response);
  }
);

export default service;

export default {
  ru: {
    hint: "Нажмите, или перетащите файл в это окно",
    loading: "Загружаю……",
    noSupported:
      "Ваш браузер не поддерживается, пожалуйста, используйте IE10 + или другие браузеры",
    success: "Загрузка выполнена успешно",
    fail: "Ошибка загрузки",
    preview: "Предпросмотр",
    btn: {
      off: "Отменить",
      close: "Закрыть",
      back: "Назад",
      save: "Сохранить",
    },
    error: {
      onlyImg: "Только изображения",
      outOfSize: "Изображение превышает предельный размер: ",
      lowestPx: "Минимальный размер изображения: ",
    },
  },
};

/**
 * @typedef {-1 | 0 | 1 | 2 | 3} JournalActivityType
 */

/**
 * @typedef JournalActivity
 * @prop {string} name
 * @prop {JournalActivityType} type
 * @prop {string} [routeName]
 */

/**
 * @typedef Activity
 * @prop {number} id
 * @prop {JournalActivityType} type
 * @prop {string} title
 * @prop {number} topReward
 * @prop {string} startDate
 * @prop {string} endDate
 * @prop {number} status
 */

/**
 * @type {JournalActivity[]}
 */
export const journalActivityTypes = [
  {
    name: "Все",
    type: -1, // All
  },
  {
    name: "Командные цели",
    type: 0, // TeamTarget
    routeName: "teamGoal",
  },
  {
    name: "Командные тестирования",
    type: 1, // TeamTesting
    routeName: "teamTest",
  },
  {
    name: "Турнирные таблицы",
    type: 2, // TeamTournamentTable
    routeName: "tournamentTable",
  },
  {
    name: "Бонусные задачи",
    type: 3, // BonusTask
    routeName: "bonusTask",
  },
];

<template>
  <nav>
    <ul>
      <li v-if="envDev">
        <router-link :to="{ name: 'kit' }">DevKit</router-link>
      </li>
      <li><router-link :to="{ name: 'home' }">Карта</router-link></li>

      <li class="hover">
        <router-link
          :to="{
            name: 'teamGoal',
          }"
          :class="{
            'router-link-active': [
              'teamGoal',
              'teamTest',
              'tournamentTable',
              'bonusTask',
            ].includes($route.name),
          }"
          >Командные задачи
          <ul>
            <li>
              <router-link :to="{ name: 'teamGoal' }"
                >Командные цели</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'teamTest' }"
                >Командные тестирования</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'tournamentTable' }"
                >Турнирные таблицы</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'bonusTask' }"
                >Бонусные задачи</router-link
              >
            </li>
          </ul>
        </router-link>
      </li>

      <li>
        <router-link
          :to="{
            name: 'usersCoins',
          }"
          :class="{
            'router-link-active':
              $route.name === 'usersCoins' ||
              $route.name === 'usersGratitudes' ||
              $route.name === 'usersIdeas' ||
              $route.name === 'usersBattles' ||
              $route.name === 'user',
          }"
          >Рейтинг</router-link
        >
      </li>
      <li>
        <router-link :to="{ name: 'battles' }">Поединок</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'store' }">Виртуальный магазин</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  computed: {
    envDev() {
      if (process.env.NODE_ENV === "development") {
        return true;
      }

      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  > ul {
    display: flex;
    list-style: none;
    padding-left: 0;
    padding: 0;
    margin: 0;
    align-items: center;
    margin-left: 20px;
    > li {
      margin-left: 10px;
      height: 70px;
      display: flex;
      align-items: center;
      &.hover {
        cursor: pointer;
        &:hover {
          ul {
            display: block;
          }
        }
        > span,
        > a {
          position: relative;
          &::before {
            position: absolute;
            top: 50%;
            left: 100%;
            width: 14px;
            height: 7px;
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='7' viewBox='0 0 14 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.1038 0 0 1.208 6.9999 7 14 1.208 12.8962 0 6.9999 4.8788 1.1037 0Z' fill='%23fff' fill-opacity='.8'/%3E%3C/svg%3E");
            content: "";
            margin-top: -1px;
            margin-left: -14px;
          }
        }
      }
      a,
      span {
        &:hover {
          opacity: 1;
        }
        padding: 0 20px;
        display: block;
        height: 100%;
        display: flex;
        align-items: center;
        opacity: 0.8;
        font-size: 18px;
        color: #ffffff;
        text-decoration: none;
        opacity: 0.8;
        &[href="/"].router-link-exact-active,
        &:not([href="/"]).router-link-active {
          opacity: 1;
          cursor: default;
          font-weight: bold;
          letter-spacing: -0.5px;
        }
      }
      ul {
        position: absolute;
        top: 100%;
        list-style: none;
        padding-left: 0;
        padding: 0;
        margin: 0;
        background: #000;
        padding: 10px 20px;
        display: none;
        margin-left: -20px;
        cursor: default;
        font-weight: initial;
        > li {
          padding: 5px 0;
          > a {
            padding: 5px 0;
            display: block;
            white-space: nowrap;
            &[href="/"].router-link-exact-active,
            &:not([href="/"]).router-link-active {
              opacity: 1;
              cursor: default;
              font-weight: bold;
              letter-spacing: -0.5px;
            }
          }
        }
      }
    }
  }
}
</style>

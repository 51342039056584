import { GetTeamsRating } from "@/api/Rating";
import { TEAMS_ERROR, TEAMS_FETCH, TEAMS_SUCCESS } from "../actions/teams";

const state = {
  teams: [],
  status: "",
};

const getters = {
  getTeams: (state) => state.teams,
  getTeamsStatus: (state) => state.status,
};

const actions = {
  [TEAMS_FETCH]: ({ commit }) => {
    return new Promise((resolve) => {
      commit(TEAMS_FETCH);
      GetTeamsRating()
        .then((resp) => {
          commit(TEAMS_SUCCESS, resp.data);
          resolve();
        })
        .catch(() => {
          commit(TEAMS_ERROR);
        });
    });
  },
};

const mutations = {
  [TEAMS_FETCH]: (state) => {
    state.status = "loading";
  },
  [TEAMS_SUCCESS]: (state, data) => {
    state.status = "success";
    state.teams = data;
  },
  [TEAMS_ERROR]: (state) => {
    state.status = "error";
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

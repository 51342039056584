<template>
  <modal
    class="modal-overlay theme"
    :name="name"
    height="auto"
    :scrollable="true"
    @before-open="beforeOpen"
    @before-close="beforeClose"
  >
    <ModalLayout :name="name" :hideClose="hideClose">
      <div class="modal-task">
        <div class="modal-task__name">
          {{ typeKPI ? "Задание KPI" : "Задание off-game" }}
        </div>
        <div class="modal-task__title">{{ data.title }}</div>
        <div class="modal-task__date">
          Получено: {{ data.acceptedDate | formatDateTime2 }}
        </div>
        <div class="modal-task__group">
          <div class="modal-task__cell">
            <span>Награда за успешное выполнение</span><br />
            <strong class="coin">
              <span v-if="data.threeReward >= 0 && data.maxReward"
                >от {{ data.threeReward | priceFilter }} до
                {{ data.maxReward | priceFilter }}</span
              >
              <span v-else-if="data.fourReward >= 0 && data.maxReward"
                >от {{ data.fourReward | priceFilter }} до
                {{ data.maxReward | priceFilter }}</span
              >
              <span v-else>{{ data.maxReward | priceFilter }}</span>
              <img
                src="@/assets/img/pic2/coin.svg"
                width="15"
                height="15"
                alt=""
              />
              <span>{{ COINS }}</span></strong
            >
          </div>
          <div class="modal-task__cell">
            <span>Выполнить до:</span><br />
            <strong
              ><span>{{
                formatDateEnd(data.dateToComplete) | formatDate2
              }}</span></strong
            >
          </div>
        </div>
        <div class="modal-task__body">
          <div class="modal-task__message">{{ data.description }}</div>
          <button
            @click="$modal.hide(name)"
            class="button button-default button_big modal-task__button"
          >
            {{ buttonName }}
          </button>
        </div>
      </div>
    </ModalLayout>
  </modal>
</template>

<script>
import ModalLayout from "@/layout/ModalLayout.vue";
import { addDay } from "@/utils";

export default {
  components: {
    ModalLayout,
  },
  data() {
    return {
      name: "kpi",
      typeKPI: true,
      data: {},
      buttonName: "Взять задание",
      hideClose: false,
    };
  },
  methods: {
    beforeOpen(event) {
      if (event.params.type === "offGame") {
        this.typeKPI = false;
      } else {
        this.typeKPI = true;
      }

      if (event.params.buttonName) {
        this.buttonName = event.params.buttonName;
      } else {
        this.buttonName = "Взять задание";
      }

      if (event.params.hideClose) {
        this.hideClose = true;
      } else {
        this.hideClose = false;
      }

      if (event.params.data) {
        this.data = event.params.data;
      } else {
        this.data = {
          id: 0,
          questType: 0,
          state: 0,
          acceptedDate: "2020-02-02T19:45:21.571Z",
          dateToComplete: "2020-02-02T19:45:21.571Z",
          title: "Название задания",
          description:
            "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat",
          comment: "string",
          maxReward: 50,
          threeReward: 10,
        };
      }

      setTimeout(() => {
        document.getElementsByClassName("modal-close")[0].focus();
      }, 1000);
    },
    beforeClose() {
      this.$emit("close");
    },
    formatDateEnd(date) {
      return date ? addDay(new Date(date), -1) : date;
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <vue-form
    :state="formstate"
    @submit.prevent="onSubmit"
    :class="{ submit: isSubmitting }"
  >
    <validate v-for="(question, index) in questions" :key="index">
      <select
        v-if="initControl(index)"
        :name="`answers_${index}`"
        required
        v-model="answers[index]"
        :multiple="!!question.isMultiple"
        style="display: none"
      >
        <option
          v-for="(answer, index2) in question.answers"
          :key="index2"
          :value="answer"
        >
          {{ answer }}
        </option>
      </select>
      <TestItem
        :model-value="question.currentAnswers"
        @update:modelValue="(answers) => onUpdate(index, answers)"
        :question="question.questionName || question.question"
        :answers="question.answers"
        :playerAnswers="question.playerAnswers"
        :correctAnswers="question.correctAnswers"
        :is-multiple="!!question.isMultiAnswer"
      >
        <field-messages :name="'answers_' + index" show="$submitted">
          <div class="error" slot="required">Выберите вариант ответа</div>
        </field-messages>
      </TestItem>

      <!-- <div>
        <TestItem
          @update:modelValue="onUpdate"
          question="Вопрос1"
          :answers="['Ответ 1', 'Ответ 2', 'Ответ 3']"
          >Ответьте на вопрос</TestItem
        >
      </div>
      <TestItem
        question="Вопрос2"
        :answers="['Ответ 1', 'Ответ 2', 'Ответ 3']"
        :playerAnswers="['Ответ 1']"
        :correctAnswers="['Ответ 1']"
      ></TestItem>
      <TestItem
        question="Вопрос3"
        :answers="['Ответ 1', 'Ответ 2', 'Ответ 3']"
        :playerAnswers="['Ответ 1']"
        :correctAnswers="['Ответ 2']"
      ></TestItem>
      <div>
        <TestItem
          @update:modelValue="onUpdate"
          question="Вопрос1"
          :isMultiple="true"
          :answers="['Ответ 1', 'Ответ 2', 'Ответ 3']"
        ></TestItem>
      </div>
      <TestItem
        question="Вопрос2"
        :isMultiple="true"
        :answers="['Ответ 1', 'Ответ 2', 'Ответ 3']"
        :playerAnswers="['Ответ 1', 'Ответ 2']"
        :correctAnswers="['Ответ 1', 'Ответ 2']"
      ></TestItem>
      <TestItem
        question="Вопрос3"
        :isMultiple="true"
        :answers="['Ответ 1', 'Ответ 2', 'Ответ 3']"
        :playerAnswers="['Ответ 1', 'Ответ 2']"
        :correctAnswers="['Ответ 1', 'Ответ 3']"
      ></TestItem> -->
    </validate>
    <slot>
      <button
        :disabled="isSubmitting"
        type="submit"
        class="button button-default button_big modal-task__button"
        :class="{ loader: isSubmitting }"
      >
        Отправить
        <Loading
          v-if="isSubmitting"
          position="center"
          class="button-loader small"
        />
      </button>
    </slot>
  </vue-form>
</template>

<script>
import Loading from "./Loading.vue";
import TestItem from "./TestItem.vue";

export default {
  components: {
    TestItem,
    Loading,
  },
  emits: ["submit"],
  props: {
    questions: {
      type: Array,
      required: true,
    },
    isSubmitting: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      formstate: {},
      answers: [],
    };
  },
  methods: {
    initControl(index) {
      this.answers[index] = this.answers[index] ?? [];
      return this.answers[index];
    },
    normalizeAnswers(data) {
      return data.map((question, i) =>
        question.map((answer) =>
          (this.questions[i].answers.indexOf(answer) + 1).toString()
        )
      );
    },
    onSubmit() {
      if (this.formstate.$invalid) {
        return;
      }

      this.$emit("submit", this.normalizeAnswers(this.answers));
      this.formstate._reset();
    },
    onUpdate(index, answers) {
      this.answers[index] = answers;
      this.questions[index].currentAnswers = answers;
      this.formstate._validate(); // валидируем formstate вручную
    },
  },
};
</script>

<style lang="scss" scoped>
.submit {
  &::v-deep [type="button"] {
    pointer-events: none;
  }
}
</style>

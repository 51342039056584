<template>
  <modal
    class="modal-overlay theme"
    :name="name"
    height="auto"
    :scrollable="true"
    @before-open="beforeOpen"
    @before-close="beforeClose"
  >
    <ModalLayout :name="name">
      <div class="modal-task">
        <div class="modal-task__name">Тест</div>
        <div class="modal-task__title">{{ data.title }}</div>
        <div class="modal-task__date">
          Получено: {{ data.acceptedDate | formatDateTime2 }}
        </div>
        <div class="modal-task__group">
          <div v-if="isResult" class="modal-task__cell">
            <span>Награда за выполнение</span><br />
            <strong class="coin">
              <span>{{ data.receivedCoins | priceFilter }}</span>
              <img
                src="@/assets/img/pic2/coin.svg"
                width="15"
                height="15"
                alt=""
              />
              <span>{{ COINS }}</span></strong
            >
          </div>
          <div v-else class="modal-task__cell">
            <span>Награда за успешное выполнение</span><br />
            <strong class="coin">
              <span v-if="data.threeReward >= 0 && data.maxReward"
                >от {{ data.threeReward | priceFilter }} до
                {{ data.maxReward | priceFilter }}</span
              >
              <span v-else-if="data.fourReward >= 0 && data.maxReward"
                >от {{ data.fourReward | priceFilter }} до
                {{ data.maxReward | priceFilter }}</span
              >
              <span v-else>{{ data.maxReward | priceFilter }}</span>
              <img
                src="@/assets/img/pic2/coin.svg"
                width="15"
                height="15"
                alt=""
              />
              <span>{{ COINS }}</span></strong
            >
          </div>
          <div class="modal-task__cell">
            <span>Выполнить до:</span><br />
            <strong
              ><span>{{
                formatDateEnd(data.dateToComplete) | formatDate2
              }}</span></strong
            >
          </div>
        </div>
        <div class="modal-task__body">
          <TestForm v-if="isResult" :questions="data.questions">
            <button
              type="button"
              class="button button-default button_big modal-task__button"
              @click="$modal.hide(name)"
            >
              Закрыть
            </button>
          </TestForm>
          <TestForm
            v-else-if="data.questions && data.questions.length"
            :questions="data.questions"
            :isSubmitting="submit"
            @submit="onSubmit"
          />
        </div>
      </div>
    </ModalLayout>
  </modal>
</template>

<script>
import { SaveTestAnswers, StartTestQuest } from "@/api/PlayerQuests";
import TestForm from "@/components/TestForm.vue";
import ModalLayout from "@/layout/ModalLayout.vue";
import { addDay } from "@/utils";
import { mapActions } from "vuex";

export default {
  components: {
    ModalLayout,
    TestForm,
  },
  computed: {
    isResult() {
      return this.data.state !== 1; // 1 получено
    },
  },
  data() {
    return {
      name: "test",
      questId: null,
      data: {},
      submit: false,
    };
  },
  methods: {
    ...mapActions(["USER_FETCH"]),
    beforeOpen(event) {
      if (event.params) {
        this.questId = event.params.questId;
        this.fetchData();
      } else {
        this.$modal.hide(this.name);
        this.$modal.show("message", {
          message: "Не передан questId",
        });
      }
      setTimeout(() => {
        document.getElementsByClassName("modal-close")[0].focus();
      }, 1000);
    },
    beforeClose() {
      this.$emit("close");
    },
    normalizeAnswers(data) {
      data.questions.forEach((question) => {
        question.currentAnswers = [];

        question.playerAnswers = question.answerResult?.map(
          (e) => question.answers[Number(e) - 1]
        );
        question.correctAnswers = question.correctAnswerIds?.map(
          (e) => question.answers[Number(e) - 1]
        );
      });
      return data;
    },
    fetchData() {
      StartTestQuest({ questId: this.questId })
        .then((resp) => {
          this.data = this.normalizeAnswers(resp.data);
        })
        .catch((err) => {
          this.$modal.hide(this.name);
          this.$modal.show("message", {
            message: err ? err.data : "Произошла ошибка. Попробуйте позже!",
          });
        });
    },
    onSelectAnswer(question, index) {
      this.$set(question, "answer", index);
    },
    onSubmit(answers) {
      const temp = {
        questId: this.data.id,
        answers: [],
      };

      this.submit = true;

      this.data.questions.forEach((item, index) => {
        const question = {
          questionId: item.id,
          answer: answers[index],
        };

        temp.answers.push(question);
      });

      SaveTestAnswers(temp)
        .then((resp) => {
          if (!resp.data) {
            throw new Error("Пустой ответ");
          }

          this.data = this.normalizeAnswers(resp.data);
          this.USER_FETCH();
        })
        .catch((err) => {
          this.$modal.show("message", {
            message: err ? err.data : "Произошла ошибка. Попробуйте позже!",
          });
        })
        .finally(() => {
          this.submit = false;
        });
    },
    formatDateEnd(date) {
      return date ? addDay(new Date(date), -1) : date;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-content {
  .question-wrapper {
    display: flex;
    padding: 0 20px;
  }
  .answer-wrapper {
    display: flex;
    outline: none;
    background: none;
    border: none;
    width: 100%;
    padding: 5px 20px;
    margin-top: 10px;
    position: relative;
    &:hover {
      background-color: rgba(0, 255, 254, 0.15);
    }
  }
  .modal-text-question {
    font-family: $fontRafale;
    font-size: 19px;
    color: #ffffff;
    margin: 0;
    margin-bottom: 10px;
  }
  .question-number {
    font-family: $fontRafale;
    font-size: 19px;
    color: #ffffff;
    margin-right: 4px;
  }
  .button-margin-top {
    margin-top: 40px;
  }
}

.radio-button {
  position: absolute;
  border: 2px solid #00ffff;
  border-radius: 50%;
  outline: none;
  top: 0;
  margin-top: 7px;
  width: 20px;
  height: 20px;
  background: none;
}

.selected {
  &.bold {
    font-weight: normal;
  }
  &.radio-button-selected {
    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 10px;
      background: #00ffff;
      box-shadow: 0px 0px 4px #2dffff;
      margin: -5px;
      border-radius: 10px;
      content: "";
    }
  }
}
.answer {
  font-family: $fontRafale;
  font-size: 19px;
  color: #95e5eb;
  margin-left: 32px;
}

.question {
  &-item {
    position: relative;
    margin-top: 40px;
    background: rgba(0, 255, 254, 0.15);
    border-left: 4px solid #00ffff;
    border-right: 4px solid #00ffff;
    padding: 20px 0;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      pointer-events: none;
      background-image: url("data:image/svg+xml,%3Csvg width='8' height='4' viewBox='0 0 8 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='8' height='4' fill='%2300FFFF'/%3E%3C/svg%3E%0A"),
        url("data:image/svg+xml,%3Csvg width='8' height='4' viewBox='0 0 8 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='8' height='4' fill='%2300FFFF'/%3E%3C/svg%3E%0A"),
        url("data:image/svg+xml,%3Csvg width='8' height='4' viewBox='0 0 8 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='8' height='4' fill='%2300FFFF'/%3E%3C/svg%3E%0A"),
        url("data:image/svg+xml,%3Csvg width='8' height='4' viewBox='0 0 8 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='8' height='4' fill='%2300FFFF'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: 0 0, 0 100%, 100% 0, 100% 100%;
      content: "";
    }
  }
}
</style>

import request from "@/utils/request";

export async function GetBattleHistory(query) {
  // return {
  //   data: [
  //     {
  //       id: 0,
  //       startDay: "2022-10-12T16:28:23.182Z",
  //       winnerReward: 1000,
  //       looserReward: 200,
  //       reward: 10,
  //       playerMatchState: 1,
  //       kpi: "КПИ123",
  //       playerKpiValue: 123,
  //       opponentKpiValue: 456,
  //       playerName: "string1",
  //       opponentName: "string2",
  //       playerPhoto: "string3",
  //       opponentPhoto: null,
  //       fightVsShadow: true,
  //     },
  //     {
  //       id: 0,
  //       startDay: "2022-10-12T16:28:23.182Z",
  //       winnerReward: 1000,
  //       looserReward: 200,
  //       reward: 10,
  //       playerMatchState: 1,
  //       kpi: "КПИ123",
  //       playerKpiValue: 123,
  //       opponentKpiValue: 456,
  //       playerName: "string1",
  //       opponentName: "string2",
  //       playerPhoto: null,
  //       opponentPhoto: null,
  //       fightVsShadow: true,
  //     },
  //     {
  //       id: 0,
  //       startDay: "2022-10-12T16:28:23.182Z",
  //       winnerReward: 1000,
  //       looserReward: 200,
  //       reward: 10,
  //       playerMatchState: 3,
  //       kpi: "КПИ123",
  //       playerKpiValue: 123,
  //       opponentKpiValue: 456,
  //       playerName: "string1",
  //       opponentName: "string2",
  //       playerPhoto: "string3",
  //       opponentPhoto: "null",
  //       fightVsShadow: false,
  //     },
  //     {
  //       id: 0,
  //       startDay: "2022-10-12T16:28:23.182Z",
  //       winnerReward: 1000,
  //       looserReward: 200,
  //       reward: 10,
  //       playerMatchState: 2,
  //       kpi: "КПИ123",
  //       playerKpiValue: 123,
  //       opponentKpiValue: 456,
  //       playerName: "string1",
  //       opponentName: "string2",
  //       playerPhoto: "string3",
  //       opponentPhoto: null,
  //       fightVsShadow: true,
  //     },
  //     {
  //       id: 0,
  //       startDay: "2022-10-12T16:28:23.182Z",
  //       winnerReward: 1000,
  //       looserReward: 200,
  //       reward: 10,
  //       playerMatchState: 1,
  //       kpi: "КПИ123",
  //       playerKpiValue: 123,
  //       opponentKpiValue: 456,
  //       playerName: "string1",
  //       opponentName: "string2",
  //       playerPhoto: "string3",
  //       opponentPhoto: null,
  //       fightVsShadow: false,
  //     },
  //   ],
  // };
  const resp = await request({
    url: "/api/Player/Battles/GetBattleHistory",
    method: "get",
    params: query,
  });

  if (resp?.data?.length) {
    resp.data = resp.data.filter((e) => e.playerMatchState > 0);
  }

  return resp;
}

export function GetCurrentBattle(query) {
  return request({
    url: "/api/Player/Battles/GetCurrentBattle",
    method: "get",
    params: query,
  });
}

export function BattlesParticipate() {
  return request({
    url: "/api/Player/Battles/Participate",
    method: "post",
  });
}

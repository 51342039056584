import request from "@/utils/request";

export function GetPlayerQuestsStates(query) {
  return request({
    url: "/api/Player/PlayerQuests/GetPlayerQuestsStates",
    method: "get",
    params: query,
  });
}

export function GetCaptainQuestsCount(query) {
  return request({
    url: "/api/Player/PlayerQuests/GetCaptainQuestsCount",
    method: "get",
    params: query,
  });
}

export function CaptainSetQuests(data) {
  return request({
    url: "/api/Player/PlayerQuests/CaptainSetQuests",
    method: "post",
    data,
  });
}

export function GetQuestByID(query) {
  return request({
    url: "/api/Player/PlayerQuests/GetQuestByID",
    method: "get",
    params: query,
  });
}

export function StartTestQuest(query) {
  return request({
    url: "/api/Player/PlayerQuests/StartTestQuest",
    method: "get",
    params: query,
  });
}

export function SaveTestAnswers(data) {
  return request({
    url: "/api/Player/PlayerQuests/SaveTestAnswers",
    method: "post",
    data,
  });
}

export function StartQuestionQuest(query) {
  return request({
    url: "/api/Player/PlayerQuests/StartQuestionQuest",
    method: "get",
    params: query,
  });
}

export function SaveQuestionAnswers(data) {
  return request({
    url: "/api/Player/PlayerQuests/SaveQuestionAnswers",
    method: "post",
    data,
  });
}

<template>
  <modal
    class="modal-overlay default"
    :name="name"
    height="auto"
    :scrollable="true"
    :clickToClose="false"
    @before-open="beforeOpen"
  >
    <ModalLayout :name="name" type="default">
      <div class="modal-list">
        <div v-show="step === 0" class="modal-item">
          <div class="modal-pic">
            <img class="pic-0" src="~@/assets/img/pics/tutorial/0.png" alt="" />
          </div>
          <div>
            <div class="modal-title">Обучение</div>
            <div class="modal-desc">
              Добро пожаловать в&nbsp;игру! Здесь вы&nbsp;и&nbsp;ваши
              коллеги&nbsp;&mdash; супергерои Дефендеры. По&nbsp;легенде игры
              вам предстоит принять участие в&nbsp;великой миссии Совета
              Старейшин Обливии: найти все Камни величия и&nbsp;помочь построить
              Мегамолот&nbsp;&mdash; оружие, которое поможет победить злодея
              Краноса. В&nbsp;вашем распоряжении находятся Фалконы&nbsp;&mdash;
              корабли, способные передвигаться между мирами.
            </div>
          </div>
          <div class="modal-foot">
            <button
              @click="next"
              class="button-default button_big modal-button"
            >
              Начать обучение
            </button>
          </div>
        </div>
        <div v-show="step === 1" class="modal-item">
          <div class="modal-pic">
            <img class="pic-1" src="~@/assets/img/pics/tutorial/1.png" alt="" />
          </div>
          <div class="modal-desc">
            Перед вами навигатор Дефендера с&nbsp;секретной картой планет нашего
            мира. <br />
            Ваша задача&nbsp;&mdash; исследовать планеты, находить
            и&nbsp;выполнять задания.
            <br />
            За&nbsp;это вы&nbsp;будете получать знаки отличия и&nbsp;GPB-coins.
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>
              Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее<span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 2" class="modal-item">
          <div class="modal-pic">
            <img class="pic-2" src="~@/assets/img/pics/tutorial/2.png" alt="" />
          </div>
          <div class="modal-desc">
            Ваша первая миссия на&nbsp;планете Асирис. Заходите на&nbsp;планету,
            <br />
            нажимайте на&nbsp;ячейки, находите в&nbsp;них задания
            и&nbsp;выполняйте&nbsp;их.
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>
              Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее<span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 3" class="modal-item">
          <div class="modal-pic">
            <img class="pic-3" src="~@/assets/img/pics/tutorial/3.png" alt="" />
          </div>
          <div class="modal-desc">
            В&nbsp;каждой ячейке спрятаны гермокапсулы с&nbsp;заданиями.<br />
            Открывайте капсулы и&nbsp;берите задания.
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>
              Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее<span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 4" class="modal-item">
          <div class="modal-pic">
            <img class="pic-4" src="~@/assets/img/pics/tutorial/4.png" alt="" />
          </div>
          <div class="modal-desc">
            Так выглядят капсулы с&nbsp;заданиями. Выбирайте любую
            из&nbsp;них.<br />
            И&nbsp;не&nbsp;останавливайтесь, пока не&nbsp;откроете все ячейки
            планеты. Чем больше <br />
            заданий вы&nbsp;найдете, возьмете и&nbsp;выполните, тем быстрее вы
            продвинетесь в&nbsp;игре.
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>
              Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее<span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 5" class="modal-item">
          <div class="modal-pic">
            <img class="pic-5" src="~@/assets/img/pics/tutorial/5.png" alt="" />
          </div>
          <div class="modal-desc">
            GPB-coins&nbsp;&mdash; это игровая валюта. Она начисляется
            за&nbsp;выполнение заданий. На&nbsp;нее вы&nbsp;можете купить
            подарки в&nbsp;магазине, который располагается на&nbsp;планете
            Обливия. Количество заработанных GPB-coins влияет на&nbsp;ваше место
            в&nbsp;рейтинге игроков: чем больше сумма заработанных
            монет&nbsp;&mdash; тем выше вы&nbsp;в&nbsp;рейтинге.
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>
              Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее<span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 6" class="modal-item">
          <div class="modal-pic">
            <img
              class="pic-5-1"
              src="~@/assets/img/pics/tutorial/5-1.png"
              alt=""
            />
          </div>
          <div class="modal-desc">
            Обо всех события в игре вы получаете уведомления. <br />Они хранятся
            здесь.
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>
              Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее<span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 7" class="modal-item">
          <div class="modal-pic">
            <img
              class="pic-5-2"
              src="~@/assets/img/pics/tutorial/5-2.png"
              alt=""
            />
          </div>
          <div class="modal-desc">
            В&nbsp;профиле игрока вы&nbsp;можете посмотреть данные о&nbsp;себе,
            изменить аватар и&nbsp;ник, и&nbsp;настроить рассылку писем.
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>
              Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее<span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 8" class="modal-item">
          <div class="modal-pic">
            <img
              class="pic-5-3"
              src="~@/assets/img/pics/tutorial/5-3.png"
              alt=""
            />
          </div>
          <div class="modal-desc">
            В кабинете команды хранятся данные о командных заданиях.
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>
              Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее<span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 9" class="modal-item">
          <div class="modal-pic">
            <img class="pic-6" src="~@/assets/img/pics/tutorial/6.png" alt="" />
          </div>
          <div class="modal-desc">
            Обливия&nbsp;&mdash; домашняя база Дефендеров, где располагается
            магазин, <br />в котором GPB-coins можно обменять на&nbsp;призы.
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>
              Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее<span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 10" class="modal-item">
          <div class="modal-pic">
            <img class="pic-9" src="~@/assets/img/pics/tutorial/9.png" alt="" />
          </div>
          <div class="modal-desc">
            Все индивидуальные задания и&nbsp;поединки вы&nbsp;можете посмотреть
            в&nbsp;виджете &laquo;Статус задач&raquo;.
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>
              Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее<span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 11" class="modal-item">
          <div class="modal-pic">
            <img
              class="pic-10"
              src="~@/assets/img/pics/tutorial/10.png"
              alt=""
            />
          </div>
          <div class="modal-desc">
            В&nbsp;виджете &laquo;Статус задач&raquo; можно посмотреть текущие
            и&nbsp;завершенные индивидуальные задания, а&nbsp;также статус
            по&nbsp;индивидуальным поединкам.
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>
              Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее<span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 12" class="modal-item">
          <div class="modal-pic">
            <img
              class="pic-12"
              src="~@/assets/img/pics/tutorial/12.png"
              alt=""
            />
          </div>
          <div class="modal-desc">
            Кроме индивидуальных заданий вас ждут командные задачи. За&nbsp;них
            вы&nbsp;также будете получать GPB-coins. Информация о&nbsp;них
            хранится в&nbsp;Кабинете команды.
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>
              Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее<span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 13" class="modal-item">
          <div class="modal-pic">
            <img
              class="pic-13"
              src="~@/assets/img/pics/tutorial/13.png"
              alt=""
            />
          </div>
          <div class="modal-desc">
            Одна из&nbsp;командных задач&nbsp;&mdash; командная цель. Здесь вам
            вместе с&nbsp;командой нужно выполнить одну задачу и&nbsp;достичь
            общего результата. Результат каждого участника влияет
            на&nbsp;результат команды, а&nbsp;награда распределяется согласно
            вкладу.
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>
              Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее<span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 14" class="modal-item">
          <div class="modal-pic">
            <img
              class="pic-14"
              src="~@/assets/img/pics/tutorial/14.png"
              alt=""
            />
          </div>
          <div class="modal-desc">
            В&nbsp;Турнирных таблицах будут задачи, актуальные для нескольких
            команд. Чем быстрее ваша команда выполнит задачу, тем выше место она
            займет в&nbsp;турнирной таблице и&nbsp;тем больше будет ваша
            награда.
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>
              Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее<span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 15" class="modal-item">
          <div class="modal-pic">
            <img
              class="pic-16"
              src="~@/assets/img/pics/tutorial/16.png"
              alt=""
            />
          </div>
          <div class="modal-desc">
            Для получения награды за&nbsp;прохождение командного тестирования
            нужно выполнить два условия: <br />
            1. Набрать достаточный средний балл правильных ответов
            по&nbsp;команде. <br />
            2 .Обеспечить прохождение теста необходимым количеством участников.
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>
              Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее<span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 16" class="modal-item">
          <div class="modal-pic">
            <img
              class="pic-17"
              src="~@/assets/img/pics/tutorial/17.png"
              alt=""
            />
          </div>
          <div class="modal-desc">
            Бонусная задача&nbsp;&mdash; это командная задача, которую нужно
            выполнять каждый месяц. При выполнении в&nbsp;каждом месяце, команда
            также получает награду ежемесячно.
          </div>
          <div class="modal-foot">
            <button
              @click="back"
              class="button-default button-back button_big modal-button"
            >
              <span></span>
              Назад
            </button>
            <button
              @click="next"
              class="button-default button-next button_big modal-button"
            >
              Далее<span></span>
            </button>
          </div>
        </div>
        <div v-show="step === 17" class="modal-item">
          <div class="modal-pic">
            <img
              class="pic-15"
              src="~@/assets/img/pics/tutorial/15.png"
              alt=""
            />
          </div>
          <div class="modal-desc">
            Отлично, защитник!<br />
            Вы прошли инструктаж и готовы к миссиям.
          </div>
          <div class="modal-foot">
            <button
              @click="startGame"
              class="button-default button_big modal-button"
            >
              Завершить обучение
            </button>
          </div>
        </div>
      </div>
    </ModalLayout>
  </modal>
</template>

<script>
import { CompleteTutorial } from "@/api/Player";
import { MarkNotification } from "@/api/PlayerNotifications";
import ModalLayout from "@/layout/ModalLayout.vue";
import { mapActions } from "vuex";

export default {
  components: {
    ModalLayout,
  },
  data() {
    return {
      name: "tutorial",
      step: 0,
      notifyID: undefined,
      hideClose: false,
    };
  },
  methods: {
    ...mapActions(["USER_FETCH_NOTIFY"]),
    back() {
      this.step--;
    },
    next() {
      this.step++;
    },
    beforeOpen(event) {
      this.step = 0;
      if (event.params) {
        this.notifyID = event.params.id;
        this.hideClose = true;
      }
    },
    startGame() {
      this.$modal.hide(this.name);

      if (this.notifyID !== undefined) {
        MarkNotification({ notificationId: this.notifyID }).then(() => {
          this.USER_FETCH_NOTIFY();
        });
      }

      CompleteTutorial();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &-item {
    display: grid;
    grid-template-rows: 1fr auto 100px;
    height: calc(70vh - #{$headerHeight});
    min-height: 500px;
    max-height: 650px;
    margin-top: -60px;
  }
  &-pic {
    position: relative;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: auto;
      height: 100%;
      transform: translate3d(-50%, -50%, 0);
    }
    .pic {
      &-1 {
        height: 130%;
      }
      &-3 {
        height: 70%;
      }
      &-5 {
        width: 468px;
        height: 170px;
        margin-top: 45px;
        &-1 {
          width: 468px;
          height: 165px;
          margin-bottom: -100px;
        }
        &-2 {
          width: 517px;
          height: 265px;
          margin-top: 30px;
        }
        &-3 {
          width: 505px;
          height: 287px;
        }
      }
      &-6 {
        height: 90%;
      }
      &-7 {
        height: auto;
        width: 120%;
      }
      &-8 {
        height: auto;
        width: 120%;
      }
      &-9 {
        height: 80%;
        margin-top: -25px;
      }
      &-10 {
        margin-top: 25px;
      }
      &-11 {
        height: 80%;
      }
      &-12 {
        height: 80%;
      }
      &-13 {
        height: 80%;
      }
      &-14 {
        height: 80%;
      }
      &-15 {
        height: 80%;
      }
      &-16 {
        height: 80%;
      }
      // &-15 {
      //   height: 80%;
      //   margin-top: 10px;
      // }
    }
  }
  &-button {
    margin-left: 7px;
    margin-right: 7px;
  }
  &-desc {
    font-size: 18px;
    font-family: $font;
    line-height: 1.4;
  }
}
</style>

<template>
  <modal
    class="modal-overlay"
    :name="name"
    height="75%"
    @before-open="beforeOpen"
  >
    <div class="modal-content">
      <button class="modal-close" @click="$modal.hide(name)">
        <svg width="46" height="46" viewBox="0 0 46 46" fill="none">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6 6h34v34H6V6zm1.2828 1.2828v31.4344h31.4344V7.2828H7.2828z"
            fill="#0FF"
          />
          <g opacity=".6" fill="#0FF">
            <path
              d="M46 46H34v-2.8237h9.1765V34H46v12zM12 46H0V34h2.8237v9.1763H12V46zM2.8237 12H0V0h12v2.8234H2.8237V12zM46 12h-2.8235V2.8234H34V0h12v12z"
            />
          </g>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6 39.0828L39.0828 6 40 6.9172 6.9172 40 6 39.0828z"
            fill="#5EFFFF"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M38.1368 39L7 7.8633 7.8632 7 39 38.1367 38.1368 39z"
            fill="#0FF"
          />
        </svg>
      </button>
      <img :src="src" alt="" />
    </div>
  </modal>
</template>

<script>
export default {
  data() {
    return {
      name: "photo",
      src: null,
    };
  },
  methods: {
    beforeOpen(event) {
      this.src = event.params.src;
    },
  },
};
</script>

<style lang="scss" scoped>
.v--modal-overlay {
  background: rgba(51, 51, 51, 0.9) !important;
}

.modal-close {
  position: absolute;
  top: -50px;
  right: -60px;
  background: none;
  border: none;
  z-index: 100;
  &:hover {
    svg {
      path {
        fill: rgb(154, 255, 255);
      }
    }
  }
}

.modal-content {
  position: relative;
  height: 45vh;
  padding-left: 0;
  padding-right: 0;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='274' viewBox='0 0 24 274' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 274h24v-9H8.2808V10H24V0H0v274z' fill='%230FF'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: auto 100%;
    content: "";
  }
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='274' viewBox='0 0 24 274' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 274h24v-9H8.2808V10H24V0H0v274z' fill='%230FF'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: auto 100%;
    content: "";
    transform: rotate(180deg);
  }
  img {
    height: 100%;
    width: auto;
  }
}
</style>

import { addDays, format } from "date-fns";
import { ru } from "date-fns/locale";
import _ from "lodash";

export function sorting(arr, _options, reverse) {
  let options = _.clone(_options);
  let iteratees = [];
  let orders = [];

  if (reverse) _.reverse(options);

  options.forEach((item) => {
    iteratees.push(item.field);
    orders.push(item.orderBy);
  });

  return _.orderBy(arr, iteratees, orders);
}

export function declOfNum(n, titles) {
  return titles[
    n % 10 === 1 && n % 100 !== 11
      ? 0
      : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
      ? 1
      : 2
  ];
}

export function getBaseURL(isAuth) {
  let baseUrl;

  if (process.env.VUE_APP_API_HOST || window.env?.VUE_APP_API_HOST) {
    baseUrl = process.env.VUE_APP_API_HOST || window.env?.VUE_APP_API_HOST;
  } else {
    baseUrl = `//${window.location.hostname}`;
  }

  if (isAuth) {
    baseUrl += `:${
      process.env.VUE_APP_AUTH_PORT || window.env?.VUE_APP_AUTH_PORT
    }`;
  } else {
    baseUrl += `:${
      process.env.VUE_APP_API_PORT || window.env?.VUE_APP_API_PORT
    }`;
  }

  // eslint-disable-next-line no-console
  console.log(baseUrl);
  return baseUrl;
}

export function isJSON(str) {
  try {
    const json = JSON.parse(str);
    if (typeof json !== "object") return false;
  } catch (e) {
    return false;
  }
  return true;
}

export function addDay(date, day) {
  return date && day ? addDays(new Date(date), day) : date;
}

export function formatDateTime(date) {
  return date
    ? format(new Date(date), "dd.MM.yyyy HH:mm", { locale: ru })
    : date;
}

export function mathRoundToDecimal(number) {
  if (number > 0) {
    return +number.toFixed(2);
  }

  return number;
}

<template>
  <modal
    class="modal-overlay default"
    :name="name"
    height="auto"
    :scrollable="true"
    @before-open="beforeOpen"
  >
    <ModalLayout :name="name" type="default">
      <div class="modal-head-pic">
        <img src="@/assets/img/pic2/dron.png" width="200" height="199" alt="" />
      </div>
      <div class="modal-title">
        {{ type | gratitudeTypeFilter }}
      </div>
      <div class="modal-date">
        {{ gratitude.time | formatDateTime2 }}
      </div>
      <div class="modal-users">
        {{ gratitude.sender }}
        <span class="arrow">
          <svg width="64" height="8" viewBox="0 0 64 8" fill="none">
            <path
              d="M63.3536 4.3536a.5001.5001 0 000-.7071l-3.182-3.182a.5.5 0 10-.7071.707L62.2929 4l-2.8284 2.8284a.5.5 0 10.7071.7071l3.182-3.182zM0 4.5h63v-1H0v1z"
              fill="#5CFFED"
            />
          </svg>
        </span>
        {{ gratitude.receiver }}
      </div>
      <div class="modal-message">
        <div v-if="gratitude.coins" class="modal-coins">
          <div class="field-score">
            <span
              >{{ gratitude.coins }}
              <span class="coin">
                <img
                  src="@/assets/img/pic2/coin.svg"
                  width="15"
                  height="15"
                  alt=""
                />
              </span>
              {{ COINS }}</span
            >
          </div>
        </div>
        <div v-if="gratitude.message" class="small">
          «<span>{{ gratitude.message }}</span
          >»
        </div>
      </div>
      <button
        @click="handleClick"
        class="button button-default button_big modal-button"
      >
        {{ textButton }}
      </button>
    </ModalLayout>
  </modal>
</template>

<script>
import ModalLayout from "@/layout/ModalLayout.vue";

const gratitudeTypeOptions = [
  { key: 0, display_name: "Вы получили благодарность!" },
  { key: 1, display_name: "Вы отправили благодарность!" },
];

const gratitudeTypeKeyValue = gratitudeTypeOptions.reduce((acc, cur) => {
  acc[cur.key] = cur.display_name;
  return acc;
}, {});

export default {
  components: {
    ModalLayout,
  },
  filters: {
    gratitudeTypeFilter(type) {
      return gratitudeTypeKeyValue[type];
    },
  },
  data() {
    return {
      name: "gratitude",
      type: null,
      gratitude: {},
      textButton: "Спасибо",
    };
  },
  methods: {
    beforeOpen(event) {
      // type 0 - получил, 1 - отправил
      this.type = event.params.type;
      this.gratitude = event.params.data;
    },
    handleClick() {
      this.$modal.hide(this.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &-button {
    margin-top: 50px;
    margin-bottom: 0;
  }
}
.coin {
  margin-top: 2px;
  margin-left: 5px;
  margin-right: 15px;
  img {
    // width: 100%;
    display: block;
  }
}
.arrow {
  margin: 0 30px;
}
</style>

export const USERS_COINS_FETCH = "USERS_COINS_FETCH";
export const USERS_COINS_SUCCESS = "USERS_COINS_SUCCESS";
export const USERS_COINS_ERROR = "USERS_COINS_ERROR";
export const USERS_COINS_PROFILE_FETCH = "USERS_COINS_PROFILE_FETCH";
export const USERS_COINS_PROFILE_SUCCESS = "USERS_COINS_PROFILE_SUCCESS";
export const USERS_COINS_PROFILE_ERROR = "USERS_COINS_PROFILE_ERROR";
export const USERS_GRATITUDES_FETCH = "USERS_GRATITUDES_FETCH";
export const USERS_GRATITUDES_SUCCESS = "USERS_GRATITUDES_SUCCESS";
export const USERS_GRATITUDES_ERROR = "USERS_GRATITUDES_ERROR";
export const USERS_IDEAS_FETCH = "USERS_IDEAS_FETCH";
export const USERS_IDEAS_SUCCESS = "USERS_IDEAS_SUCCESS";
export const USERS_IDEAS_ERROR = "USERS_IDEAS_ERROR";
export const USERS_BATTLES_FETCH = "USERS_BATTLES_FETCH";
export const USERS_BATTLES_SUCCESS = "USERS_BATTLES_SUCCESS";
export const USERS_BATTLES_ERROR = "USERS_BATTLES_ERROR";

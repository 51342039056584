import { authenticate, ldapAuthenticate } from "@/api/Authentication";
import router from "@/router";
import { getToken, removeToken, setToken } from "@/utils/auth";
import {
  AUTH_ERROR,
  AUTH_FETCH,
  AUTH_LOGOUT,
  AUTH_SUCCESS,
} from "../actions/auth";

const state = {
  token: getToken(),
  status: "",
};

const getters = {
  isAuthenticated: (state) => !!state.token,
  authStatus: (state) => state.status,
};

const actions = {
  [AUTH_FETCH]: ({ commit }, userName) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_FETCH);

      if (userName) {
        authenticate({ userName: userName })
          .then((resp) => {
            commit(AUTH_SUCCESS, resp.data);
            setToken(resp.data);
            resolve();
          })
          .catch((error) => {
            commit(AUTH_ERROR);
            commit(AUTH_LOGOUT);
            router.push({ name: "denied" });
            reject(error);
          });
      } else {
        ldapAuthenticate()
          .then((resp) => {
            commit(AUTH_SUCCESS, resp.data);
            setToken(resp.data);
            resolve();
          })
          .catch((error) => {
            commit(AUTH_ERROR);
            // commit(AUTH_LOGOUT);
            // router.push({ name: 'denied' });
            reject(error);
          });
      }
    });
  },
  [AUTH_LOGOUT]: ({ commit }) => {
    return new Promise((resolve) => {
      removeToken();
      commit(AUTH_LOGOUT);
      resolve();
    });
  },
};

const mutations = {
  [AUTH_FETCH]: (state) => {
    state.status = "loading";
  },
  [AUTH_SUCCESS]: (state, token) => {
    state.status = "success";
    state.token = token;
  },
  [AUTH_ERROR]: (state) => {
    state.status = "error";
  },
  [AUTH_LOGOUT]: (state) => {
    state.token = "";
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

<template>
  <modal
    class="modal-overlay theme"
    :name="name"
    height="auto"
    :scrollable="true"
    @before-open="beforeOpen"
    @before-close="beforeClose"
  >
    <ModalLayout :name="name">
      <div class="modal-task">
        <div class="modal-task__name">Вопрос</div>
        <div class="modal-task__title">{{ data.title }}</div>
        <div class="modal-task__desc">{{ data.description }}</div>
        <div class="modal-task__date">
          Получено: {{ data.acceptedDate | formatDateTime2 }}
        </div>
        <div class="modal-task__group">
          <div class="modal-task__cell">
            <span>Награда за успешное выполнение</span><br />
            <strong class="coin">
              <span v-if="data.threeReward >= 0 && data.maxReward"
                >от {{ data.threeReward | priceFilter }} до
                {{ data.maxReward | priceFilter }}</span
              >
              <span v-else-if="data.fourReward >= 0 && data.maxReward"
                >от {{ data.fourReward | priceFilter }} до
                {{ data.maxReward | priceFilter }}</span
              >
              <span v-else>{{ data.maxReward | priceFilter }}</span>
              <img
                src="@/assets/img/pic2/coin.svg"
                width="15"
                height="15"
                alt=""
              />
              <span>{{ COINS }}</span></strong
            >
          </div>
          <div class="modal-task__cell">
            <span>Выполнить до:</span><br />
            <strong
              ><span>{{
                formatDateEnd(data.dateToComplete) | formatDate2
              }}</span></strong
            >
          </div>
        </div>
        <div class="modal-task__body">
          <vue-form :state="formstate" @submit.prevent="onSubmit">
            <validate v-for="(item, index) in data.questions" :key="index">
              <h2 class="modal-title">{{ item.questionName }}</h2>
              <p class="modal-text-all">{{ item.questionDescription }}</p>
              <textarea
                v-model="item.answer"
                :name="'answer_' + index"
                required
                placeholder="Начните писать ответ здесь"
                class="modal-textarea"
              ></textarea>
              <field-messages :name="'answer_' + index" show="$submitted">
                <div class="error" slot="required">
                  Поле обязательно для заполнения
                </div>
              </field-messages>
            </validate>
            <button
              :disabled="
                formstate.$submittedState && formstate.$submittedState.$valid
              "
              type="submit"
              class="button button-default button_big modal-task__button"
            >
              Ответить
            </button>
          </vue-form>
        </div>
      </div>
    </ModalLayout>
  </modal>
</template>

<script>
import { SaveQuestionAnswers, StartQuestionQuest } from "@/api/PlayerQuests";
import ModalLayout from "@/layout/ModalLayout.vue";
import { addDay } from "@/utils";
import { mapActions } from "vuex";

const tempDefault = {
  questId: null,
  answers: [],
};

export default {
  components: {
    ModalLayout,
  },
  data() {
    return {
      name: "question",
      questId: null,
      data: {},
      formstate: {},
      temp: tempDefault,
    };
  },
  methods: {
    ...mapActions(["USER_FETCH"]),
    beforeOpen(event) {
      if (event.params) {
        this.questId = event.params.questId;
        this.fetchData();
      } else {
        this.$modal.hide(this.name);
        this.$modal.show("message", {
          message: "Не передан questId",
        });
      }
      setTimeout(() => {
        document.getElementsByClassName("modal-close")[0].focus();
      }, 1000);
    },
    beforeClose() {
      this.resetForm();
      this.$emit("close");
    },
    fetchData() {
      StartQuestionQuest({ questId: this.questId })
        .then((resp) => {
          this.data = resp.data;
        })
        .catch((err) => {
          this.$modal.hide(this.name);
          this.$modal.show("message", {
            message: err ? err.data : "Произошла ошибка. Попробуйте позже!",
          });
        });
    },
    resetForm() {
      this.formstate._reset();
      this.temp = {
        questId: null,
        answers: [],
      };
    },
    onSubmit() {
      if (this.formstate.$invalid) {
        return;
      }

      this.temp.questId = this.data.id;
      this.data.questions.forEach((item) => {
        const question = {
          question: item.id,
          answer: item.answer,
        };

        this.temp.answers.push(question);
      });

      SaveQuestionAnswers(this.temp)
        .then(() => {
          this.$modal.hide(this.name);
          this.$modal.show("message", {
            message: "Ваш ответ принят и направлен на модерацию.",
          });
          this.USER_FETCH();
        })
        .catch(this.handleError);
    },
    formatDateEnd(date) {
      return date ? addDay(new Date(date), -1) : date;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-task__body {
  .modal-title {
    margin-top: 40px;
    margin-bottom: 0;
  }
}
.modal-textarea {
  background: #024a4b;
  border: 1px solid #00ffff;
  width: 100%;
  height: 150px;
  color: rgba(94, 255, 238, 1);
  font-size: 18px;
  padding: 10px 20px;
  &::placeholder {
    color: rgba(47, 189, 174, 1);
  }
}
</style>

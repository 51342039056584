<template>
  <div class="test-item" :class="{ wrong: isWrong, multiple: isMultiple }">
    <div class="test-item__inner">
      <div class="test-item__error"><slot /></div>
      <div class="test-item__title">{{ question }}</div>
      <div class="test-item__desc">
        {{ isMultiple ? "Несколько вариантов ответа" : "1 вариант ответа" }}
      </div>
      <div class="test-item__list">
        <button
          v-for="(answer, index) in answers"
          type="button"
          :key="index"
          class="test-item__button"
          :disabled="isDisable"
          :class="{
            selected:
              (modelValue && modelValue.includes(answer)) ||
              (playerAnswers && playerAnswers.includes(answer)),
            correct:
              playerAnswers &&
              correctAnswers &&
              correctAnswers.includes(answer),
            wrong:
              playerAnswers &&
              correctAnswers &&
              playerAnswers.includes(answer) &&
              !correctAnswers.includes(answer),
            multiple: isMultiple,
          }"
          @click="onSelect(answer)"
        >
          <span></span>{{ answer }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Array,
      required: false,
    },
    question: {
      type: String,
      required: true,
    },
    answers: {
      type: Array,
      required: true,
    },
    playerAnswers: {
      type: Array,
      required: false,
    },
    correctAnswers: {
      type: Array,
      required: false,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isWrong() {
      if (!this.playerAnswers) return false;
      if (!this.correctAnswers) return false;

      let result = false;

      if (this.playerAnswers.length !== this.correctAnswers.length) {
        result = true;

        return result;
      }

      this.playerAnswers.forEach((answer) => {
        if (!this.correctAnswers.includes(answer)) {
          result = true;
        }
      });

      return result;
    },
    isDisable() {
      return this.playerAnswers;
    },
  },
  data() {
    return {};
  },
  methods: {
    onSelect(answer) {
      const newArr = this.modelValue || [];
      const selectedIndex = newArr.findIndex((e) => e === answer);

      if (selectedIndex === -1) {
        newArr.push(answer);
      } else {
        newArr.splice(selectedIndex, 1);
      }

      this.$emit("update:modelValue", this.isMultiple ? newArr : [answer]);
    },
  },
};
</script>

<style lang="scss" scoped>
.test-item {
  text-align: left;
  position: relative;
  margin-top: 40px;
  background: rgba(0, 255, 254, 0.15);
  border-left: 4px solid #00ffff;
  border-right: 4px solid #00ffff;
  padding: 20px 30px;
  // подсвечиваем весь блок красным
  &.wrong {
    border-left-color: #ff6161;
    border-right-color: #ff6161;
    background: rgba(255, 97, 97, 0.15);
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='8' height='4' viewBox='0 0 8 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='8' height='4' fill='%23FF6161'/%3E%3C/svg%3E%0A"),
        url("data:image/svg+xml,%3Csvg width='8' height='4' viewBox='0 0 8 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='8' height='4' fill='%23FF6161'/%3E%3C/svg%3E%0A"),
        url("data:image/svg+xml,%3Csvg width='8' height='4' viewBox='0 0 8 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='8' height='4' fill='%23FF6161'/%3E%3C/svg%3E%0A"),
        url("data:image/svg+xml,%3Csvg width='8' height='4' viewBox='0 0 8 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='8' height='4' fill='%23FF6161'/%3E%3C/svg%3E%0A");
    }
  }
  // делаем варианты ответов с множественным выбором квадратными
  &.multiple {
    .test-item {
      &__button {
        span {
          border-radius: 4px;
        }
      }
    }
  }
  // оформление вопроса по бокам
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='4' viewBox='0 0 8 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='8' height='4' fill='%2300FFFF'/%3E%3C/svg%3E%0A"),
      url("data:image/svg+xml,%3Csvg width='8' height='4' viewBox='0 0 8 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='8' height='4' fill='%2300FFFF'/%3E%3C/svg%3E%0A"),
      url("data:image/svg+xml,%3Csvg width='8' height='4' viewBox='0 0 8 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='8' height='4' fill='%2300FFFF'/%3E%3C/svg%3E%0A"),
      url("data:image/svg+xml,%3Csvg width='8' height='4' viewBox='0 0 8 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='8' height='4' fill='%2300FFFF'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: 0 0, 0 100%, 100% 0, 100% 100%;
    content: "";
  }
  &__inner {
    position: relative;
  }
  &__error {
    position: absolute;
    top: 0;
    right: 0;
    color: rgba(200, 249, 138, 1);
    font-size: 19px;
    font-family: $fontRafale;
  }
  &__title {
    font-size: 19px;
    font-family: $fontRafale;
    margin-right: 150px;
  }
  &__desc {
    font-size: 14px;
    font-family: $fontRafale;
    margin-top: 4px;
  }
  &__list {
    margin-top: 10px;
    // display: grid;
    // gap: 12px;
    // justify-content: start;
  }
  &__button {
    @include reset-button;
    display: grid;
    align-items: center;
    font-size: 19px;
    font-family: $fontRafale;
    gap: 12px;
    text-align: left;
    grid-auto-flow: column;
    align-items: start;
    color: #00ffff;
    width: 100%;
    justify-content: start;
    margin: 0 -30px;
    padding: 0 30px;
    // оформление для отображения результатов
    &:disabled {
      cursor: default;
      // выбранный вариант пользователем
      &.selected {
        span {
          background-color: #10f466;
          border-color: #10f466;
          &::before {
            content: "";
          }
        }
        &.correct {
          color: #10f466;
          span {
            box-shadow: 0 0 4px 0 #10f466;
          }
        }
      }
      // верный выбор
      &.correct {
        span {
          background-color: #10f466;
          border-color: #10f466;
          &::before {
            content: "";
          }
        }
      }
      // неверный выбор
      &.wrong {
        span {
          background-color: #ff6161;
          border-color: #ff6161;
          box-shadow: 0 0 4px 0 #ff6161;
          &::after {
            content: "";
          }
          &::before {
            content: none;
          }
        }
      }
    }
    // оформление для выбора вариантов
    &:not(:disabled) {
      &.selected {
        &:not(.multiple) {
          span {
            &::before {
              width: 10px;
              height: 10px;
              background: #00ffff;
              border-radius: 10px;
              content: "";
            }
          }
        }
        &.multiple {
          span {
            &::before {
              // галочка
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='8' fill='none'%3E%3Cpath stroke='%2300ffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M11 1 5 7 1 3.356'/%3E%3C/svg%3E");
              content: "";
            }
          }
        }
      }
    }
    + button {
      margin-top: 10px;
    }
    & span {
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      border: 2px solid currentColor;
      margin-top: 3px;
      position: relative;
      &::before,
      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
      }
      &::before {
        // галочка
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='8' fill='none'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M11 1 5 7 1 3.356'/%3E%3C/svg%3E");
        width: 12px;
        height: 8px;
      }
      &::after {
        // крестик
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' fill='none'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-width='2' d='M9 1.04 1 9M1.04 1 9 9'/%3E%3C/svg%3E");
        width: 10px;
        height: 10px;
      }
    }
  }
}
</style>

import { Hit } from "@/api/PlayerHit";
import router from "@/router";

export default function () {
  router.beforeEach((to, from, next) => {
    let { path } = to;

    Hit(path);
    // todo на беке нужно поправить, возвращает 401
    next();
  });
}

<template>
  <modal
    class="modal-overlay default"
    :name="name"
    :scrollable="true"
    height="auto"
    @before-open="beforeOpen"
  >
    <ModalLayout :name="name">
      <div class="modal-title" v-if="title" v-html="title"></div>
      <div class="modal-date" v-if="desc" v-html="desc"></div>
      <div class="modal-date" v-if="date" v-html="date"></div>
      <div class="modal-message" v-if="message">
        <span v-if="message">
          <template v-if="quotes">«</template><span v-html="message"></span
          ><template v-if="quotes">»</template></span
        >
      </div>
      <div
        class="modal-date modal-date_type_foot"
        v-if="caption"
        v-html="caption"
      ></div>
      <div class="modal-foot">
        <button @click="handleClick" class="button button-default button-modal">
          {{ textButton }}
        </button>
      </div>
    </ModalLayout>
  </modal>
</template>

<script>
import ModalLayout from "@/layout/ModalLayout.vue";

export default {
  components: {
    ModalLayout,
  },
  data() {
    return {
      name: "messageLarge",
      title: "",
      desc: "",
      date: "",
      message: "",
      caption: "",
      textButton: "ОК",
      cb: null,
    };
  },
  methods: {
    beforeOpen(event) {
      const { title, desc, date, message, caption, quotes } = event.params;

      this.title = title;
      this.desc = desc;
      this.date = date;
      this.message = message;
      this.caption = caption;
      this.quotes = quotes;

      this.cb = event.params.cb;
      if (event.params.textButton) {
        this.textButton = event.params.textButton;
      }
      setTimeout(() => {
        document.getElementsByClassName("modal-close")[0].focus();
      }, 1000);
    },
    handleClick() {
      this.$modal.hide(this.name);
      if (typeof this.cb === "function") {
        this.cb();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-content-reduce {
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  width: 640px;
  &::before {
    display: block;
    height: 300px;
    content: "";
  }
  &__title {
    font-weight: bold;
    font-size: 30px;
    padding: 50px 20px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }
}
</style>

import request from "@/utils/request";

export function GetOwnTeam(query) {
  return request({
    url: "/api/Player/Team/GetOwnTeam",
    method: "get",
    params: query,
  });
}

export function GetTeamById(query) {
  return request({
    url: "/api/Player/Team/GetTeamById",
    method: "get",
    params: query,
  });
}

import request from "@/utils/request";

export function UpdateNickname(query) {
  return request({
    url: "/api/Player/Player/UpdateNickname",
    method: "post",
    params: query,
  });
}

export function GetProfile(query) {
  return request({
    url: "/api/Player/Player/GetProfile",
    method: "get",
    params: query,
  });
}

export function GetOtherPlayerProfile(query) {
  return request({
    url: "/api/Player/Player/GetOtherPlayerProfile",
    method: "get",
    params: query,
  });
}

export function GetFullProfile(query) {
  return request({
    url: "/api/Player/Player/GetFullProfile",
    method: "get",
    params: query,
  });
}

export function UploadProfilePhoto(data) {
  return request({
    url: "/api/Player/Player/UploadProfilePhoto",
    method: "post",
    data,
  });
}

export function GetOpenedIslands(query) {
  return request({
    url: "/api/Player/PlayerIsland/GetOpenedIslands",
    method: "get",
    params: query,
  });
}

export function GetCurrentIsland(query) {
  return request({
    url: "/api/Player/PlayerIsland/GetCurrentIsland",
    method: "get",
    params: query,
  });
}

export function GetIslandByIndex(query) {
  return request({
    url: "/api/Player/PlayerIsland/GetIslandByIndex",
    method: "get",
    params: query,
  });
}

export function OpenCell(query) {
  return request({
    url: "/api/Player/PlayerIsland/OpenCell",
    method: "post",
    params: query,
  });
}

export function GetPlayerCoinHistory(query) {
  return request({
    url: "/api/Player/Player/GetPlayerCoinHistory",
    method: "get",
    params: query,
  });
}

export function GetPlayerKPIHistory(query) {
  return request({
    url: "/api/Player/Player/GetPlayerKPIHistory",
    method: "get",
    params: query,
  });
}

export function DeleteProfilePhoto() {
  return request({
    url: "/api/Player/Player/DeleteProfilePhoto",
    method: "delete",
  });
}

export function CompleteTutorial() {
  return request({
    url: "/api/Player/Player/CompleteTutorial",
    method: "post",
  });
}

export function GetTeamProfile() {
  return request({
    url: "/api/Player/Player/GetTeamProfile",
    method: "get",
  });
}

export function GetTeamActivities() {
  return request({
    url: "/api/Player/Player/GetTeamActivities",
    method: "get",
  });
}

export function GetBonusTask() {
  return request({
    url: "/api/Player/BonusTask/GetBonusTask",
    method: "get",
  });
}

export function GetBonusTaskHistory(params) {
  return request({
    url: "/api/Player/BonusTask/GetBonusTaskHistory",
    method: "get",
    params,
  });
}

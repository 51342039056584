<template>
  <CommonLayout v-if="isAuthenticated" class="page_fullscreen">
    <template>
      <button
        @click="$modal.show('legend', { closeTutorial: true })"
        class="control-legend button button-default"
      >
        ИНТРО
      </button>
      <button
        @click="$modal.show('tutorial')"
        class="control-learn button button-default"
      >
        Обучение
      </button>
      <Loading v-if="loading" position="center" theme="game" />
      <canvas ref="map" width="1920" height="900"></canvas>

      <!-- предзакгрузка открытых ячеек на островах -->
      <div style="display: none">
        <div v-for="(island, indexIsland) in islandsOpened" :key="indexIsland">
          <img
            v-for="(number, index) in ISLAND_CELLS_AMOUNT"
            :key="index"
            :src="`/static/img/game/island/${island}/PNG/${numberCell(
              index
            )}.png`"
            alt=""
          />
        </div>
      </div>
    </template>
    <template #modals>
      <div class="modals">
        <ModalIslandBefore />
        <ModalIslandAfter />
      </div>
    </template>
  </CommonLayout>
</template>

<script>
import ModalIslandAfter from "@/components/ModalIslandAfter.vue";
import ModalIslandBefore from "@/components/ModalIslandBefore.vue";
import {
  backgroundImage,
  backgroundIslandStore,
  backgroundIslandStoreHover,
  backgroundIslandStoreName,
  backgroundShip,
  islandList,
  islandStore,
} from "@/data/game";
import CommonLayout from "@/layout/CommonLayout.vue";
import { numberWithZero, patternToIslandMedia } from "@/utils/game";
import { hasModal } from "@/utils/modalStorage";
import { fabric } from "fabric";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    CommonLayout,
    ModalIslandBefore,
    ModalIslandAfter,
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "profileStatus",
      "getGame",
      "getGameStatus",
      "getNewNotify",
      "getProfile",
      "getProfileOpenedIslands",
      "getProfileCurrentIsland",
    ]),
    loading() {
      return this.profileStatus === "loading";
    },
  },
  watch: {
    profileStatus(status) {
      if (status === "success") {
        this.init();
      }
    },
  },
  data() {
    return {
      canvas: null,
      islands: islandList,
      islandStore: islandStore,
      islandStoreDefault: {},
      islandStoreHover: {},
      islandsOpened: [],
      islandsDefault: [],
      islandsHover: [],
      ship: {},
    };
  },
  mounted() {
    if (this.profileStatus === "success") {
      this.init();
    }
  },
  methods: {
    ...mapActions(["USER_FETCH", "GAME_FETCH"]),
    init() {
      const map = this.$refs.map;
      const options = {
        enableRetinaScaling: false,
        hoverCursor: "pointer",
        selection: false,
      };

      this.canvas = new fabric.Canvas(map, options);

      // todo проверять запрос gameState
      // if (!this.getGame.isGameStarted) {
      //   this.$modal.show('message', {
      //     message: 'Игра не началась!'
      //   });
      // }

      setTimeout(() => {
        this.addBackground();
        this.addIslands();
        this.addIslandStore();
        this.addShip();
        this.handleHoverIsland();
        this.handleClickIsland();

        this.showLegend();
        // this.showTutorial();
        this.showIslandAutro();
      }, 0);
    },
    addBackground() {
      this.canvas.setBackgroundImage(
        backgroundImage,
        this.canvas.renderAll.bind(this.canvas)
      );
    },
    getStateIsland(index) {
      let state = "lock";

      if (
        this.getProfileCurrentIsland.islandIdx !== null &&
        this.getProfileCurrentIsland.islandIdx >= index
      ) {
        state = "open";
      }

      this.getProfileOpenedIslands.forEach((item) => {
        if (item === index && index <= this.getProfileCurrentIsland.islandIdx) {
          state = "completed";
        }
      });
      return state;
    },
    addIslands() {
      this.islands.forEach((island, index) => {
        const state = this.getStateIsland(index);

        switch (state) {
          case "completed":
            this.addArea(island);
            this.addIsland(island, "completed");
            this.addIslandHover(island, "completed");
            this.addIslandName(island, state);
            this.islandsOpened.push(island.name);
            break;
          case "open":
            this.addArea(island);
            this.addIsland(island, "open");
            this.addIslandHover(island, "open");
            this.addIslandName(island, state);
            this.islandsOpened.push(island.name);
            break;
          default:
            this.addIsland(island, "lock", true);
            break;
        }
      });
    },
    addIslandName(island, state, locked) {
      if (locked) {
        return false;
      }

      const options = {
        state: state,
        top: island.offsetName
          ? island.offsetName.top + island.top
          : island.top,
        left: island.offsetName
          ? island.offsetName.left + island.left
          : island.left,
        hasControls: false,
        hasBorders: false,
        selectable: false,
        hoverCursor: "default",
        opacity: state === "completed" ? 1 : 0.5,
      };
      const number = numberWithZero(island.id);
      const folder = "planets-names";
      const url = patternToIslandMedia(folder, number);

      fabric.Image.fromURL(
        url,
        (oImg) => {
          this.islandsDefault.push(oImg);
          this.canvas.add(oImg).sendToBack(oImg);
          this.canvas.renderAll();
        },
        options
      );
    },
    addIsland(island, state, locked) {
      const options = {
        name: island.name,
        state: state,
        top: island.top,
        left: island.left,
        hasControls: false,
        hasBorders: false,
        selectable: false,
        hoverCursor: "default",
        opacity: state === "completed" ? 1 : 0.5,
      };
      const number = numberWithZero(island.id);
      const folder = "planets";
      const url = patternToIslandMedia(folder, number);

      fabric.Image.fromURL(
        url,
        (oImg) => {
          if (locked) {
            this.addCloud(island, oImg);
          } else {
            this.islandsDefault.push(oImg);
            this.canvas.add(oImg).sendToBack(oImg);
            this.canvas.renderAll();
          }
        },
        options
      );
    },
    addIslandHover(island, state, locked) {
      const options = {
        name: island.name,
        state: state,
        top: island.top + island.offsetHover ? island.offsetHover.top : 0,
        left: island.left + island.offsetHover ? island.offsetHover.left : 0,
        hasControls: false,
        hasBorders: false,
        selectable: false,
        hoverCursor: "default",
        opacity: 0,
      };
      const number = numberWithZero(island.id);
      const folder = "planets-hover";
      const url = patternToIslandMedia(folder, number);

      fabric.Image.fromURL(
        url,
        (oImg) => {
          if (locked) {
            this.addCloud(island, oImg);
          } else {
            this.islandsHover.push(oImg);
            this.canvas.add(oImg).sendToBack(oImg);
            this.canvas.renderAll();
          }
        },
        options
      );
    },
    addArea(island) {
      const path = new fabric.Path(island.path);
      const options = {
        perPixelTargetFind: true,
        name: island.name,
        top: island.top + island.offsetArea ? island.offsetArea.top : 0,
        left: island.left + island.offsetArea ? island.offsetArea.left : 0,
        isHover: true,
        selectable: false,
        fill: "#5074d4",
        opacity: 0.0000000001,
        // opacity: 0.5
      };

      path.set(options);
      this.canvas.add(path);
    },
    addCloud(island, islandImg) {
      const options = {
        top: island.cloudPosition.top,
        left: island.cloudPosition.left,
        hasControls: false,
        hasBorders: false,
        selectable: false,
        hoverCursor: "default",
        opacity: 1,
      };
      const folder = "fog";
      const number = numberWithZero(island.id);
      const url = patternToIslandMedia(folder, number);

      fabric.Image.fromURL(
        url,
        (oImg) => {
          this.canvas
            .add(oImg)
            .add(islandImg)
            .sendToBack(oImg)
            .sendToBack(islandImg);
        },
        options
      );
    },
    animateShip(oImg, top) {
      const start = 0;
      const end = 20;
      const animate = () => {
        fabric.util.animate({
          startValue: start,
          endValue: end,
          duration: 3000,
          // linear movement
          easing: (t, b, c, d) => {
            return (c * t) / d + b;
          },
          onChange: (step) => {
            let offset = step;
            if (step >= end / 2) {
              offset = end - step;
            }

            oImg.set({ top: offset + top }).setCoords();

            // only render once
            this.canvas.renderAll();
          },
          onComplete: animate,
        });
      };
      animate();
    },
    addShip() {
      const island = this.islands[this.getProfileCurrentIsland.islandIdx];

      let options = {
        top: island.shipPosition.top,
        left: island.shipPosition.left,
        hasControls: false,
        evented: false,
        selectable: false,
        hasBorders: false,
        hoverCursor: "default",
      };

      fabric.Image.fromURL(
        backgroundShip,
        (oImg) => {
          this.ship = oImg;
          this.canvas.add(oImg);
          this.animateShip(oImg, island.shipPosition.top);
        },
        options
      );
    },
    addIslandStore() {
      let options = {
        top: this.islandStore.top,
        left: this.islandStore.left,
        hasControls: false,
        selectable: false,
        hasBorders: false,
        hoverCursor: "default",
      };

      fabric.Image.fromURL(
        backgroundIslandStore,
        (oImg) => {
          this.islandStoreDefault = oImg;
          this.canvas.add(oImg).sendToBack(oImg);
        },
        options
      );
      this.addIslandStoreHover();
      this.addAreaStore();
      this.addIslandStoreName();
    },
    addIslandStoreHover() {
      let options = {
        top:
          this.islandStore.top +
          (this.islandStore.offsetHover ? this.islandStore.offsetHover.top : 0),
        left:
          this.islandStore.left +
          (this.islandStore.offsetHover
            ? this.islandStore.offsetHover.left
            : 0),
        hasControls: false,
        selectable: false,
        hasBorders: false,
        hoverCursor: "default",
        opacity: 0,
      };

      fabric.Image.fromURL(
        backgroundIslandStoreHover,
        (oImg) => {
          this.islandStoreHover = oImg;
          this.canvas.add(oImg).sendToBack(oImg);
        },
        options
      );
    },
    addIslandStoreName() {
      const options = {
        top: this.islandStore.offsetName.top + this.islandStore.top,
        left: this.islandStore.offsetName.left + this.islandStore.left,
        hasControls: false,
        selectable: false,
        hasBorders: false,
        hoverCursor: "default",
      };

      fabric.Image.fromURL(
        backgroundIslandStoreName,
        (oImg) => {
          this.canvas.add(oImg).sendToBack(oImg);
        },
        options
      );
    },
    addAreaStore() {
      const path = new fabric.Path(this.islandStore.path);
      const options = {
        name: "store",
        top:
          this.islandStore.top +
          (this.islandStore.offsetArea ? this.islandStore.offsetArea.top : 0),
        left:
          this.islandStore.left +
          (this.islandStore.offsetArea ? this.islandStore.offsetArea.left : 0),
        perPixelTargetFind: true,
        store: true,
        isHover: true,
        selectable: false,
        fill: "#5074d4",
        opacity: 0.0000000001,
        // opacity: 0.5
      };

      path.set(options);
      this.canvas.add(path).bringToFront(path);
    },
    handleHoverIsland() {
      this.canvas.on("mouse:over", (e) => {
        if (e.target && e.target.isHover) {
          this.islandsHover.forEach((el) => {
            if (e.target.name === el.name) {
              el.set("opacity", el.state === "open" ? 0.5 : 1);
            }
          });

          this.islandsDefault.forEach((el) => {
            if (e.target.name === el.name) {
              el.set("opacity", 0);
            }
          });

          if (e.target.name === "store") {
            this.islandStoreDefault.set("opacity", 0);
            this.islandStoreHover.set("opacity", 1);
          }

          // e.target.set("opacity", 0.4);
          this.canvas.renderAll();
        }
      });

      this.canvas.on("mouse:out", (e) => {
        if (e.target && e.target.isHover) {
          this.islandsHover.forEach((el) => {
            if (e.target.name === el.name) {
              el.set("opacity", 0);
            }
          });

          this.islandsDefault.forEach((el) => {
            if (e.target.name === el.name) {
              el.set("opacity", el.state === "open" ? 0.5 : 1);
            }
          });

          if (e.target.name === "store") {
            this.islandStoreDefault.set("opacity", 1);
            this.islandStoreHover.set("opacity", 0);
          }

          this.canvas.renderAll();
        }
      });
    },
    goToStore() {
      this.$router.push({
        name: "islandShop",
        params: { name: "obliviya" },
      });
    },
    handleClickIsland() {
      this.canvas.on("mouse:up", (e) => {
        // определяем позицию
        // alert(e.target.top + '|' + e.target.left);

        if (e.target && e.target.store) {
          this.goToStore();
          return;
        }
        if (e.target && e.target.name !== undefined && e.target.isHover) {
          this.$router.push({
            name: "island",
            params: { name: e.target.name },
          });
          return;
        }
      });
    },
    showTutorial() {
      // показываем обучение на первом острове, пока не открыта ни одна из ячеек
      if (this.getProfileCurrentIsland.islandIdx === 0) {
        let countOpen = 0;
        this.getProfileCurrentIsland.cells.forEach((cell) => {
          if (cell.cellState === 1) {
            countOpen = countOpen + 1;
          }
        });

        if (countOpen === 0) {
          this.$modal.show("tutorial");
        }
      }
    },
    // showGameOver() {
    //   // показываем модалку что игра завершена
    //   if (this.getGame.isGameOver) {
    //     this.$modal.show("message", {
    //       message: "Внимание, защитники Вселенной! Ваша игра завершена."
    //     });
    //     return true;
    //   }
    //   return false;
    // },
    showGameOver() {
      // показываем модалку что игра завершена
      if (this.getGame.isGameOver) {
        const type = "islandAfter";
        const name = this.getNameOfCurrentIsland();

        if (!hasModal(type, name)) {
          this.$modal.show(type, {
            name,
            gameOver: true,
          });
        }
        return true;
      }
      return false;
    },
    showLegend() {
      setTimeout(() => {
        if (this.checkNotifyAboutStartGame()) {
          this.$modal.show("legend");
        }
      }, 1000);
    },
    getNameOfCurrentIsland() {
      let islandName;
      let offsetIndex = this.getGame.isGameOver ? 0 : 1;
      this.islands.forEach((island, index) => {
        if (this.getProfileCurrentIsland.islandIdx - offsetIndex === index) {
          islandName = island.name;
        }
      });

      return islandName;
    },
    showIslandAutro() {
      if (this.checkNotifyAboutStartGame()) return;
      if (this.showGameOver()) return;
      if (this.getProfileCurrentIsland.islandIdx <= 0) return;

      let openedCells = 0;
      this.getProfileCurrentIsland.cells.forEach((cell) => {
        switch (cell.cellState) {
          // ячейка не открыта
          case 0:
            break;
          // ячейка открыта
          case 1:
            openedCells++;
            break;
        }
      });

      if (!openedCells) {
        const type = "islandAfter";
        const name = this.getNameOfCurrentIsland();

        if (hasModal(type, name)) {
          return;
        }

        this.$modal.show(type, {
          name,
        });
      }
    },
    checkNotifyAboutStartGame() {
      let state = false;

      this.getNewNotify.forEach((notify) => {
        if (notify.notificationType === 3) {
          state = true;
        }
      });

      return state;
    },
    numberCell(index) {
      return numberWithZero(index + 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.control {
  &-legend {
    position: absolute;
    top: 30px;
    left: 60px;
    z-index: 100;
    min-width: 216px;
  }
  &-learn {
    position: absolute;
    top: 30px;
    right: 60px;
    z-index: 100;
    min-width: 216px;
  }
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "./views/GameMapView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/island/obliviya",
    name: "islandShop",
    component: () => import("./views/GameShopView.vue"),
  },
  {
    path: "/island/:name",
    name: "island",
    props: true,
    component: () => import("./views/GameIslandView.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("./views/ProfileView.vue"),
  },
  {
    path: "/rating",
    name: "ratingTeam",
    redirect: "/rating/team",
    component: () => import("./views/RatingView.vue"),
  },
  {
    path: "/rating/team/:teamID",
    name: "team",
    props: true,
    component: () => import("./views/RatingTeamView.vue"),
  },
  {
    path: "/rating/users/coins",
    name: "usersCoins",
    component: () => import("./views/RatingUserCoinsView.vue"),
  },
  {
    path: "/rating/users/battles",
    name: "usersBattles",
    component: () => import("./views/RatingUserBattlesView.vue"),
  },
  {
    path: "/rating/user/:userID",
    name: "user",
    props: true,
    component: () => import("./views/ProfileView.vue"),
  },
  {
    path: "/rating/user/:userID/gratitude",
    name: "gratitude",
    props: true,
    component: () => import("./views/RatingGratitudeView.vue"),
  },
  {
    path: "/notify",
    name: "notify",
    component: () => import("./views/NotifyView.vue"),
  },
  {
    path: "/store",
    name: "store",
    component: () => import("./views/StoreCatalogView.vue"),
  },
  {
    path: "/store/orders",
    name: "orders",
    component: () => import("./views/StoreOrdersView.vue"),
  },
  {
    path: "/store/:id",
    name: "product",
    props: true,
    component: () => import("./views/StoreProductView.vue"),
  },
  {
    path: "/cart",
    name: "cart",
    component: () => import("./views/StoreCartView.vue"),
  },
  {
    path: "/team-goal",
    name: "teamGoal",
    component: () => import("@/views/TeamGoalView.vue"),
  },
  {
    path: "/team-goal/:id",
    name: "teamGoalHistory",
    component: () => import("@/views/TeamGoalHistoryView.vue"),
  },
  {
    path: "/team-test",
    name: "teamTest",
    component: () => import("@/views/TeamTestView.vue"),
  },
  {
    path: "/tournament-table",
    name: "tournamentTable",
    component: () => import("@/views/TeamTournamentView.vue"),
  },
  {
    path: "/bonus-task",
    name: "bonusTask",
    component: () => import("@/views/BonusTaskView.vue"),
  },
  // {
  //   path: "/achievements/:userID",
  //   name: "achievements",
  //   component: () => import("@/views/AchievementsView.vue"),
  // },
  {
    path: "/progress",
    name: "progress",
    component: () => import("@/views/404View.vue"),
  },
  {
    path: "/battles",
    name: "battles",
    component: () => import("@/views/BattleView.vue"),
  },
  {
    path: "/battles/history",
    name: "battlesHistory",
    component: () => import("@/views/BattleHistoryView.vue"),
  },
  {
    path: "/settings/notify",
    name: "settingsNotify",
    component: () => import("./views/SettingsNotifyView.vue"),
  },
  {
    path: "/team-profile",
    name: "TeamProfile",
    component: () => import("./views/TeamProfile.vue"),
  },
  {
    path: "/kit",
    name: "kit",
    component: () => import("./views/KitView.vue"),
  },
  {
    path: "/denied",
    name: "denied",
    component: () => import("./views/401View.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/404View.vue"),
  },
  { path: "*", redirect: "/404" },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

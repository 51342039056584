var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"test-item",class:{ wrong: _vm.isWrong, multiple: _vm.isMultiple }},[_c('div',{staticClass:"test-item__inner"},[_c('div',{staticClass:"test-item__error"},[_vm._t("default")],2),_c('div',{staticClass:"test-item__title"},[_vm._v(_vm._s(_vm.question))]),_c('div',{staticClass:"test-item__desc"},[_vm._v(" "+_vm._s(_vm.isMultiple ? "Несколько вариантов ответа" : "1 вариант ответа")+" ")]),_c('div',{staticClass:"test-item__list"},_vm._l((_vm.answers),function(answer,index){return _c('button',{key:index,staticClass:"test-item__button",class:{
          selected:
            (_vm.modelValue && _vm.modelValue.includes(answer)) ||
            (_vm.playerAnswers && _vm.playerAnswers.includes(answer)),
          correct:
            _vm.playerAnswers &&
            _vm.correctAnswers &&
            _vm.correctAnswers.includes(answer),
          wrong:
            _vm.playerAnswers &&
            _vm.correctAnswers &&
            _vm.playerAnswers.includes(answer) &&
            !_vm.correctAnswers.includes(answer),
          multiple: _vm.isMultiple,
        },attrs:{"type":"button","disabled":_vm.isDisable},on:{"click":function($event){return _vm.onSelect(answer)}}},[_c('span'),_vm._v(_vm._s(answer)+" ")])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }
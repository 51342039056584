import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import battle from "./modules/battle";
import game from "./modules/game";
import gratitudes from "./modules/gratitudes";
import idea from "./modules/idea";
import shop from "./modules/shop/index";
import team from "./modules/team";
import teams from "./modules/teams";
import user from "./modules/user";
import users from "./modules/users";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    auth,
    game,
    user,
    users,
    team,
    teams,
    shop,
    gratitudes,
    idea,
    battle,
  },
  strict: debug,
});

import { GetFullProfile, UpdateNickname } from "@/api/Player";
import { GetOwnUnreadNotifications } from "@/api/PlayerNotifications";
import router from "@/router";
import { mathRoundToDecimal } from "@/utils";
import {
  USER_DELETE_PHOTO,
  USER_ERROR,
  USER_FETCH,
  USER_FETCH_NOTIFY,
  USER_NICKNAME,
  USER_SUCCESS,
} from "../actions/user";

const state = {
  user: {
    profile: {},
    openedIslands: [],
    completedIslands: [],
    completedIslandsIndexes: [],
    currentIsland: {},
    lastKpiValues: [],
    kpiTotalRatings: [],
    kpiWeeklyRatings: [],
    coinTotalRatings: [],
    coinWeeklyRatings: [],
  },
  newNotify: [],
  newNotifyCount: 0,
  status: "",
};

const getters = {
  getProfile: (state) => state.user.profile,
  getProfileOpenedIslands: (state) => state.user.completedIslandsIndexes,
  getProfileCurrentIsland: (state) => state.user.currentIsland,
  getProfileTeamQuest: (state) => state.user.teamQuestState,
  getLastKpiValues: (state) => state.user.lastKpiValues,
  getLast3BusinessKpi: (state) => state.user.last3BusinessKpi,
  getCoinWeeklyRatings: (state) => state.user.coinWeeklyRatings,
  getCoinTotalRatings: (state) => state.user.coinTotalRatings,
  getNewNotify: (state) => state.newNotify,
  getNewNotifyCount: (state) => state.newNotifyCount,
  profileStatus: (state) => state.status,
};

const actions = {
  [USER_FETCH]: ({ commit, dispatch }) => {
    return new Promise((resolve) => {
      commit(USER_FETCH);
      GetFullProfile()
        .then((resp) => {
          commit(USER_SUCCESS, resp.data);
          resolve();
        })
        .catch(() => {
          dispatch("AUTH_LOGOUT");
          router.push({ name: "denied" });
          commit(USER_ERROR);
        });
    });
  },
  [USER_NICKNAME]: ({ commit }, newNickname) => {
    return new Promise((resolve, reject) => {
      UpdateNickname({ newNickname })
        .then(() => {
          commit(USER_NICKNAME, newNickname);
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [USER_FETCH_NOTIFY]: ({ commit }) => {
    return new Promise((resolve) => {
      GetOwnUnreadNotifications().then((resp) => {
        commit(USER_FETCH_NOTIFY, resp.data);
        resolve();
      });
    });
  },
};

const mutations = {
  [USER_FETCH]: (state) => {
    state.status = "loading";
  },
  [USER_SUCCESS]: (state, data) => {
    state.status = "success";

    let {
      lastKpiValues,
      last3BusinessKpi,
      coinWeeklyRatings,
      coinTotalRatings,
    } = data;

    if (lastKpiValues.length) {
      lastKpiValues.forEach((e) => {
        e.absoluteValue = mathRoundToDecimal(e.absoluteValue);
      });
    }

    // if (kpiWeeklyRatings.length) {
    //   kpiWeeklyRatings.forEach((item) => {
    //     if (item.positions.length) {
    //       item.positions.forEach((e) => {
    //         e.value = mathRoundToDecimal(e.value);
    //       });
    //       item.positions = item.positions.sort((a, b) => {
    //         return a.position - b.position;
    //       });
    //     }
    //   });
    // }

    // if (kpiTotalRatings.length) {
    //   kpiTotalRatings.forEach((item) => {
    //     if (item.positions.length) {
    //       item.positions.forEach((e) => {
    //         e.value = mathRoundToDecimal(e.value);
    //       });
    //       item.positions = item.positions.sort((a, b) => {
    //         return a.position - b.position;
    //       });
    //     }
    //   });
    // }

    if (coinWeeklyRatings && coinWeeklyRatings.length) {
      coinWeeklyRatings = coinWeeklyRatings.sort((a, b) => {
        return a.position - b.position;
      });
    }

    if (coinTotalRatings && coinTotalRatings.length) {
      coinTotalRatings = coinTotalRatings.sort((a, b) => {
        return a.position - b.position;
      });
    }

    if (last3BusinessKpi) {
      last3BusinessKpi.forEach((e) => {
        e.value = mathRoundToDecimal(e.value);
      });
    }

    state.user = data;
    if (!state.user.currentIsland) {
      state.user.openedIslands = [];
      state.user.currentIsland = {
        islandIdx: -1,
      };
    }
  },
  [USER_ERROR]: (state) => {
    state.status = "error";
  },
  [USER_NICKNAME]: (state, newNickname) => {
    state.user.profile.nickname = newNickname;
  },
  [USER_FETCH_NOTIFY]: (state, data) => {
    state.newNotify = data;
    state.newNotifyCount = data.length;
  },
  [USER_DELETE_PHOTO]: (state) => {
    state.user.profile.photo = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

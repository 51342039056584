<template>
  <modal
    class="modal-overlay"
    :name="name"
    height="auto"
    @before-open="beforeOpen"
  >
    <ModalLayout :name="name" type="small">
      <div class="modal-title" v-if="title" v-html="title"></div>
      <div class="modal-date" v-if="desc" v-html="desc"></div>
      <div class="modal-date" v-if="date" v-html="date"></div>
      <div
        class="modal-message"
        :class="{ small: messageSmall }"
        v-if="message || messageReward || reward"
      >
        <span
          v-if="messageReward"
          v-html="messageReward"
          style="font-weight: 700"
        ></span>
        <div v-if="reward" :class="{ bottom: message }" class="field-score">
          <span
            >Награда: {{ reward }}
            <span class="coin">
              <img
                src="@/assets/img/pic2/coin.svg"
                width="15"
                height="15"
                alt=""
              />
            </span>
            {{ COINS }}</span
          >
        </div>
        <span v-if="message">
          <template v-if="quotes">«</template><span v-html="message"></span
          ><template v-if="quotes">»</template></span
        >
      </div>
      <div
        class="modal-date modal-date_type_foot"
        v-if="caption"
        v-html="caption"
      ></div>
      <div class="modal-foot">
        <button @click="handleClick" class="button button-default button-modal">
          {{ textButton }}
        </button>
      </div>
    </ModalLayout>
  </modal>
</template>

<script>
import ModalLayout from "@/layout/ModalLayout.vue";

export default {
  components: {
    ModalLayout,
  },
  data() {
    return {
      name: "message",
      title: "",
      desc: "",
      date: "",
      reward: "",
      message: "",
      messageReward: "",
      messageSmall: false,
      caption: "",
      award: null,
      textButton: "ОК",
      cb: null,
      quotes: false,
    };
  },
  methods: {
    beforeOpen(event) {
      const {
        title,
        desc,
        date,
        message,
        messageReward,
        messageSmall,
        caption,
        reward,
        quotes,
      } = event.params;

      this.title = title;
      this.desc = desc;
      this.date = date;
      this.message = message;
      this.messageReward = messageReward;
      this.messageSmall = messageSmall;
      this.caption = caption;
      this.reward = reward;
      this.quotes = quotes;

      this.cb = event.params.cb;
      if (event.params.textButton) {
        this.textButton = event.params.textButton;
      } else {
        this.textButton = "ОК";
      }
    },
    handleClick() {
      this.$modal.hide(this.name);
      if (typeof this.cb === "function") {
        this.cb();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &-content {
    text-align: center;
  }
  &-title {
  }
  &-date {
    table {
      width: 100%;
    }
  }
}

.field-score {
  margin-top: 10px;
  margin-bottom: 10px;
  &.bottom {
    margin-bottom: 20px;
  }
}
.coin {
  margin-top: 2px;
  margin-left: 5px;
  margin-right: 15px;
  img {
    // width: 100%;
    display: block;
  }
}
</style>

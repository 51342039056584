<template>
  <modal
    class="modal-overlay default"
    :name="name"
    :scrollable="true"
    height="auto"
    @before-open="beforeOpen"
  >
    <ModalLayout :name="name">
      <div class="modal-team-goals-history">
        <div class="modal-title">История командных целей</div>
        <div class="modal-team-goals-history__content">
          <Loading v-if="loading" position="center" />
          <table v-else class="modal-team-goals-history-table">
            <thead>
              <tr>
                <th>Командная цель</th>
                <th width="200px">Дата начала</th>
                <th width="200px">Статус</th>
              </tr>
            </thead>
            <tbody v-if="list.length">
              <tr v-for="item in list" :key="item.id" @click="onClick(item.id)">
                <td>{{ item.title }}</td>
                <td>
                  <span>{{ item.startDate | formatDate2 }}</span>
                </td>
                <td>
                  <strong v-if="item.state === 2"
                    ><span class="orange"></span>На модерации</strong
                  >
                  <strong v-else-if="item.state === 4"
                    ><span class="red"></span>Не выполнена</strong
                  >
                  <strong v-else><span></span>Выполнена</strong>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="3">
                  Здесь будет отображаться история командных целей
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ModalLayout>
  </modal>
</template>

<script>
import { GetTeamQuestHistory } from "@/api/TeamQuest";
import Loading from "@/components/Loading.vue";
import ModalLayout from "@/layout/ModalLayout.vue";

export default {
  components: {
    ModalLayout,
    Loading,
  },
  data() {
    return {
      name: "teamGoalsHistory",
      loading: true,
      list: [],
    };
  },
  methods: {
    async fetchData() {
      try {
        const { data } = await GetTeamQuestHistory();

        this.list = data;
      } catch (error) {
        new Error(error);
      } finally {
        this.loading = false;
      }
    },
    beforeOpen() {
      this.fetchData();
    },
    onClick(id) {
      this.$router.push({ name: "teamGoalHistory", params: { id } });
      this.$modal.hide(this.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-team-goals-history {
  margin: -50px -80px;
  &-table {
    margin-top: 30px;
    text-align: left;
    border-collapse: collapse;
    width: 100%;
    thead {
      tr {
        th {
          padding-bottom: 11px;
          color: #ade5d9;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
        background: url("~@/assets/img/pic2/divider-table-short.svg") 50% 0
          no-repeat;
        td {
          padding: 18px 0;
          font-size: 18px;
          line-height: 21px;
          font-family: $fontRafale;
          > span {
            color: #ade5d9;
          }
          strong {
            display: flex;
            align-items: center;
            white-space: nowrap;
            font-weight: 400;
            > span {
              width: 8px;
              height: 8px;
              display: flex;
              border-radius: 8px;
              background-color: #00ffff;
              margin-right: 6px;
              margin-top: 3px;
              &.red {
                background-color: #ff0000;
              }
              &.orange {
                background-color: #ff9900;
              }
            }
          }
        }
      }
    }
  }
}
</style>

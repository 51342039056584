var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pane"},[_c('div',{staticClass:"pane__inner"},[_c('div',{staticClass:"col col__coins"},[_c('img',{attrs:{"src":require("@/assets/img/pic2/coin.svg"),"width":"15","height":"15","alt":""}}),_vm._v(" GPB-coins:"),_c('b',[_vm._v(_vm._s(_vm._f("priceFilter")(_vm.getProfile.coinBalance)))])]),_c('router-link',{staticClass:"col col__notify",attrs:{"to":{
        name: 'notify',
      }}},[(_vm.getNewNotifyCount)?_c('span',{staticClass:"quantity"},[_vm._v(_vm._s(_vm.getNewNotifyCount))]):_vm._e()]),_c('br'),_c('ul',{staticClass:"pane-list"},[_c('li',{staticClass:"hover"},[_c('router-link',{class:{
            'router-link-active': ['profile', 'TeamProfile'].includes(
              _vm.$route.name
            ),
          },attrs:{"to":{
            name: 'profile',
          }}},[_c('AvatarOrDefault'),_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{ name: 'profile' },"data-text":"Профиль игрока"}},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/img/battle/defender-head.svg"),"width":"27","height":"35","alt":"player"}})])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'TeamProfile' },"data-text":"Кабинет команды"}},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/img/battle/defender-head-multiple.svg"),"width":"58","height":"58","alt":"player"}})])])],1)])],1)],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <modal
    class="modal-overlay default"
    :name="name"
    :scrollable="true"
    height="auto"
    @before-open="beforeOpen"
  >
    <ModalLayout :name="name" type="default reduce">
      <div class="modal-title">
        <span class="modal-small-line"></span>
        {{ mechanic.mechanicType | mechanicTypeFilter }}
        <span class="modal-small-line"></span>
      </div>
      <div class="modal-date">
        {{ mechanic.startingWeek + 1 }} - {{ mechanic.endWeek + 1 }} неделю
      </div>
      <div class="modal-message small" v-if="mechanic.message">
        «<span>{{ mechanic.message }}</span
        >»
      </div>
      <button
        @click="handleClick"
        class="button button-default button_big modal-button"
      >
        {{ textButton }}
      </button>
    </ModalLayout>
  </modal>
</template>

<script>
import ModalLayout from "@/layout/ModalLayout.vue";

const mechanicTypeOptions = [
  { key: 0, display_name: "Генератор удачи" },
  { key: 1, display_name: "Черная дыра" },
];

const mechanicTypeKeyValue = mechanicTypeOptions.reduce((acc, cur) => {
  acc[cur.key] = cur.display_name;
  return acc;
}, {});

export default {
  components: {
    ModalLayout,
  },
  filters: {
    mechanicTypeFilter(type) {
      return mechanicTypeKeyValue[type];
    },
  },
  data() {
    return {
      name: "mechanic",
      mechanic: {},
      textButton: "ОК",
    };
  },
  methods: {
    beforeOpen(event) {
      this.mechanic = event.params;
    },
    handleClick() {
      this.$modal.hide(this.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &-desc {
    margin-top: 10px;
  }
  &-button {
    margin-top: 50px;
  }
}
</style>

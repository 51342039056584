import { GetCurrentSeason } from "@/api/IdeaSeason";
import {
  IDEA_SEASON_ERROR,
  IDEA_SEASON_FETCH,
  IDEA_SEASON_SUCCESS,
  IDEA_SEASON_UPDATE,
} from "../actions/idea";

const state = {
  season: {},
  seasonStatus: "",
};

const getters = {
  getSeason: (state) => state.season,
  getSeasonStatus: (state) => state.seasonStatus,
};

const actions = {
  [IDEA_SEASON_FETCH]: ({ commit, state }) => {
    return new Promise((resolve) => {
      if (
        state.seasonStatus === "loading" ||
        state.seasonStatus === "success"
      ) {
        return;
      }

      commit(IDEA_SEASON_FETCH);
      GetCurrentSeason()
        .then((resp) => {
          commit(IDEA_SEASON_SUCCESS, resp.data);
          resolve();
        })
        .catch(() => {
          commit(IDEA_SEASON_ERROR);
        });
    });
  },
  [IDEA_SEASON_UPDATE]: ({ commit }) => {
    return new Promise((resolve) => {
      commit(IDEA_SEASON_FETCH);
      GetCurrentSeason()
        .then((resp) => {
          commit(IDEA_SEASON_SUCCESS, resp.data);
          resolve();
        })
        .catch(() => {
          commit(IDEA_SEASON_ERROR);
        });
    });
  },
};

const mutations = {
  [IDEA_SEASON_FETCH]: (state) => {
    state.seasonStatus = "loading";
  },
  [IDEA_SEASON_SUCCESS]: (state, data) => {
    state.seasonStatus = "success";
    state.season = data;
  },
  [IDEA_SEASON_ERROR]: (state) => {
    state.seasonStatus = "error";
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

import request from "@/utils/request";

export function GetTeamsRating(query) {
  return request({
    url: "/api/Player/Rating/GetTeamsRating",
    method: "get",
    params: query,
  });
}

export function GetPlayersCoinRating(query) {
  return request({
    url: "/api/Player/Rating/GetPlayersCoinRating",
    method: "get",
    params: query,
  });
}

export function GetPlayersProfileCoinRating(query) {
  return request({
    url: "/api/Player/Rating/GetPlayersProfileCoinRating",
    method: "get",
    params: query,
  });
}

export function GetPlayersProfileKPIRating(query) {
  return request({
    url: "/api/Player/Rating/GetPlayersProfileKPIRating",
    method: "get",
    params: query,
  });
}

export function GetPlayersGratitudeRating(query) {
  return request({
    url: "/api/Player/Rating/GetPlayersGratitudeRating",
    method: "get",
    params: query,
  });
}

export function GetPlayersIdeaCoinRating(query) {
  return request({
    url: "/api/Player/Rating/GetPlayersIdeaCoinRating",
    method: "get",
    params: query,
  });
}

export function GetPlayersBattleRating(query) {
  return request({
    url: "/api/Player/Rating/GetPlayersBattleRating",
    method: "get",
    params: query,
  });
}

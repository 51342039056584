<template>
  <div class="header">
    <div class="header__cell">
      <router-link
        v-if="isAuthenticated"
        :to="{ name: 'home' }"
        class="header__logo"
      >
        <img
          width="218"
          height="48"
          alt="Газпромбанк"
          src="@/assets/img/logo.svg"
        />
      </router-link>
      <div v-else class="header__logo">
        <img
          width="218"
          height="48"
          alt="Газпромбанк"
          src="@/assets/img/logo.svg"
        />
      </div>
      <Nav v-if="isAuthenticated" />
    </div>
    <div class="header__cell">
      <Pane v-if="isAuthenticated" />
    </div>
  </div>
</template>

<script>
import Nav from "@/layout/components/Nav.vue";
import Pane from "@/layout/components/Pane.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Nav,
    Pane,
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
};
</script>

<style lang="scss" scoped>
.header {
  background: #000000;
  border-bottom: 2px solid #076259;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  min-width: $minWidth;
  min-height: $headerHeight;
  &__cell {
    display: flex;
    align-items: center;
  }
  &__logo {
    display: block;
    margin-left: 20px;
    img {
      display: block;
    }
    &.router-link-exact-active {
      cursor: default;
    }
  }
}
</style>

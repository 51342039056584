import request from "@/utils/request";

export function DownloadFile(params) {
  return request({
    url: "/api/Player/IdeaFiles/DownloadFile",
    method: "get",
    responseType: "blob",
    params,
  });
}

export function DeleteFile(params, data) {
  return request({
    url: "/api/Player/IdeaFiles/DeleteFile",
    method: "delete",
    params,
    data,
  });
}

export function UploadFile(params, data) {
  return request({
    url: "/api/Player/IdeaFiles/UploadFile",
    method: "post",
    params,
    data,
  });
}
